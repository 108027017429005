import {useContext} from "react";
import {Switch, Route, Redirect} from 'react-router-dom'

import {Home} from './pages/Home'
import {Admin} from './pages/Admin/admin'
import {AuthPage} from './pages/Auth/AuthPage'
import {Activated} from './pages/Auth/Activated'


//Сертификаты
import {CertHome} from './pages/Cert/CertHome'


//ЧЗ
import {Marking} from './pages/Marking/Marking'
import {MarkingScan} from './pages/Marking/MarkingScan'
import {MarkingScanDate} from './pages/Marking/MarkingScanDate'
import {MarkingOtchet} from './pages/Marking/MarkingOtchet'
import {MarkingSell} from './pages/Marking/MarkingSell'
import {MarkingScanMass} from './pages/Marking/MarkingScanMass'

//ЧЗ Admin
import {MarkingAdmin} from './pages/MarkingAdmin/MarkingAdmin'
import {MAdminFix} from './pages/MarkingAdmin/MAdminFix'

//WB
import {WbHome} from './pages/Wb/WbHome'
import {Wbsupply} from './pages/Wb/WbSupply'
import {Wbscan} from './pages/Wb/WbScan'
import {WbCheck} from './pages/Wb/WbCheck'
import {WbAnalyst} from './pages/Wb/WbAnalyst'
import {WbOtchet} from './pages/Wb/WbOtchet'

//Retail
import {Retail} from './pages/Retail/Retail'
import {RetailOtchet} from './pages/Retail/RetailOtchet'
import {RetailImport} from './pages/Retail/RetailImport'
import {RetailCheck} from './pages/Retail/RetailCheck'

//BX24
import {Bx24Home} from './pages/BX24/bx24'
import {Bx24Track} from './pages/BX24/bx24Track'


//Ozon
import {Ozon} from './pages/Ozon/Ozon'
import {OzonScan} from './pages/Ozon/OzonScan'
import {OzonOneC} from './pages/Ozon/Ozon1c'


//ParsCen
import {ParsCen} from './pages/ParsCen/ParsCen'
import {ParsCenAdd} from './pages/ParsCen/ParsCenAdd'
import {ParsCenOtchet} from './pages/ParsCen/ParsCenOtchet'

//qr
import {QrHome} from './pages/QR/QrHome'
import {QrGen} from './pages/QR/QrGen'

import {Context} from ".";

export const useRoutes = ({isAuthenticated, isActivated, isLoading}) => {
  

  const token = localStorage.getItem('token')
  const {store} = useContext(Context)
 
  

  if (isAuthenticated && isActivated) {
      return (
        <Switch>
            <Route path="/home" exact>
                <Home />
            </Route>


            
            <Route path="/qr" exact>
                <QrHome />
            </Route>
            <Route path="/qr/gen" exact>
                <QrGen />
            </Route>


            <Route path="/parscen" exact>
                <ParsCen />
            </Route>
            <Route path="/parscen/add" exact>
                <ParsCenAdd />
            </Route>
            <Route path="/parscen/otchet" exact>
                <ParsCenOtchet />
            </Route>
            

            <Route path="/ozon" exact>
                <Ozon />
            </Route>
            <Route path="/ozon/scan" exact>
                <OzonScan />
            </Route>
            <Route path="/ozon/1c" exact>
                <OzonOneC />
            </Route>

            <Route path="/bx24" exact>
                <Bx24Home />
            </Route>
            <Route path="/bx24/track" exact>
                <Bx24Track />
            </Route>


            <Route path="/retail" exact>
                <Retail />
            </Route>
            <Route path="/retail/otchet" exact>
                <RetailOtchet />
            </Route>
            <Route path="/retail/import" exact>
                <RetailImport />
            </Route>
            <Route path="/retail/check" exact>
                <RetailCheck />
            </Route>

            


            <Route path="/markingadmin" exact>
                <MarkingAdmin />
            </Route>
            <Route path="/markingadmin/fix" exact>
                <MAdminFix />
            </Route>


            <Route path="/marking" exact>
                <Marking />
            </Route>
            <Route path="/marking/scan" exact>
                <MarkingScan />
            </Route>
            <Route path="/marking/scanmass" exact>
                <MarkingScanMass />
            </Route>
            <Route path="/marking/scandate" exact>
                <MarkingScanDate />
            </Route>
            <Route path="/marking/otchet" exact>
                <MarkingOtchet />
            </Route>
            <Route path="/marking/sell" exact>
                <MarkingSell />
            </Route>


            <Route path="/wb" exact>
                <WbHome />
            </Route>
            <Route path="/wb/check" exact>
                <WbCheck />
            </Route>
            <Route path="/wb/supply" exact>
                <Wbsupply />
            </Route>
            <Route path="/wb/scan" exact>
                <Wbscan />
            </Route>
            <Route path="/wb/analyst" exact>
                <WbAnalyst />
            </Route>
            <Route path="/wb/otchet" exact>
                <WbOtchet />
            </Route>
            
            <Route path="/cert" exact>
                <CertHome />
            </Route>

            

            <Route path="/Admin" exact>
                <Admin />
            </Route>

            <Redirect to="/home" />
        </Switch>
      )
    }
  
    
    if (isAuthenticated && !isActivated) {
      return (
        <Switch>
            <Route path="/activated" exact>
                <Activated />
            </Route>

            <Redirect to="/activated" />
        </Switch>
      )
    }


  if (token) {
      return (
        <Switch>
          <Route path="/*" exact>

          <button
            className="delete is-small"
            onClick={()=>store.logout()}>
          </button>

          </Route>
          
          <Redirect to="/*" />
        </Switch>
      )
    }


    return (
      <Switch>
        <Route path="/" exact>
          <AuthPage />
        </Route>

        <Redirect to="/" />
    </Switch>
    )

  }
  