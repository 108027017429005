import React, {} from 'react';
import {Redirect} from 'react-router-dom'


export const Bx24Home = () => {

    return(
        <div>
            <Redirect to="/bx24/track" />
        </div>
    )
}