import $api from "../http";

export default class QrService {
    
    
    static genQr(textForQr,qtForPdf){
        return $api.post('/qr/gen', {textForQr,qtForPdf})
    }


    
}