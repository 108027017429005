import $api from "../http";

export default class Bx24Service {

    static async trackBx24(){
        return $api.post('/trackBx24', {})
    }


}

