import React, {useState} from 'react';
import OzonService from "../../service/OzonService";


import ozonCat from "../../img/ozonCat.jpg";
import ozonCat2 from "../../img/ozonCat2.jpg";

export const OzonScan = () => {
    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')
    const [code, setCode] = useState('')

    
    async function Scan(event){
        if (!load && code){
            if (event.key === 'Enter'){

                setLoad(true)
                setReply('')
                
                try {
                    const response = await OzonService.scanOzon(code)
                    console.log(response);
                    setReply(() => {
                        return(
                        <>{response.data.message}</>
                        )
                    })
                } catch (e) {
                    setReply(e.response?.data?.message + ' ' + e.response?.status)
                }
                setCode('')
                setLoad(false)

            }
        }
    }
    


    return (
        <div>
            <div className='box' style={{marginTop: 50}}>

                <center><h1 className="fs-2">Сканирование</h1></center>
                <center style={{marginTop: 25}}>{load ? <img src={ozonCat2} alt='OZON'></img> : <img src={ozonCat} alt='OZON'></img>}</center>
                
                
                <div className="input-group input-group-lg" style={{marginTop: 25}}>
                
                <span className="input-group-text" id="inputGroup-sizing-lg">Сканирование</span>
                
                <input type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    onKeyUp={Scan}
                    autoFocus={true}/>
                </div>
                <center><div style={{marginTop: 25}}>{load ? <span className="loader"></span> : <p className="fs-1">{reply}</p>}</div></center>




                <center><h1 className="fs-4" style={{marginTop: 155}}>в разработке</h1></center>
            </div>
        </div>
    )

}
