import React, {useState} from 'react';
import Bx24Service from "../../service/Bx24Service";

export const Bx24Track = () => {
    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')


    async function Сheck(){
        setLoad(true)
        setReply('')
        try {

            const response = await Bx24Service.trackBx24()
            console.log(response);
            setReply(()=>{
                return(
                    <>
                    <label style={{marginTop: 20}}>Сделки:</label>
                    <table className="table is-fullwidth" style={{marginTop: 10}}>
                    <thead>
                        <tr>
                        <th>Ссылка</th>
                        <th>Комментарий</th>
                        </tr>
                    </thead>
                    <tbody>
                        {response.data.message.map(info => 
                            <tr>
                                <p><a href={'https://kaoristore.bitrix24.ru/crm/deal/details/'+info.uid+'/'} target="_blank" rel="noreferrer">{info.uid}</a></p> 
                                <td>{info.comment}</td>
                            </tr>
                        )}
                    </tbody>
                    </table>
                    </>
                )
            })
        } catch (e) {
            console.log(e);
            setReply(e.response?.data?.message + ' ' + e.response?.status)
        }
        setLoad(false)
    }

    return(
        <div className='box' style={{marginTop: 50}}>

        <div style={{marginTop: 10}}>
        <center>
        <button
            className="btn btn-outline-secondary"
            type="button"
            id="inputGroupFileAddon04"
            onClick={Сheck}
            disabled={load}>
            Выполнить проверку трек номеров
        </button>
        </center>
        <center><div style={{marginTop: 25}}> {load ? <span className="loader"/> : <>{reply}</>}</div></center>
        </div>

    </div>
    )
}