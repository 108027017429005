import React, {useState, useEffect} from 'react';
import RetailService from "../../service/RetailService";
import { OutTable, ExcelRenderer } from "react-excel-renderer";

export const RetailImport = () => {
    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')
    const [fileObject, setFileObject] = useState('');
    const [data, setData] = useState([]);

    
    useEffect(() => {
        if (fileObject) {
          ExcelRenderer(fileObject, (err, resp) => {
            if (err) {
              console.log(err);
            } else {
              const modifyData = resp.rows?.slice(1)?.map((itm, index) => ({
                //time have a problem
                //trDate: new Date(itm[0] * 1000)?.toUTCString() || "",
                Info: itm[0] || "",
                Product: itm[1] || "",
                Sku: itm[2] || "",
                Qt: itm[3] || "",
                Price: itm[4] || ""
              }));
              setData(modifyData);
              // resp?.rows?.map()
              // console.log(resp);
            }
          });
        }
      }, [fileObject]);

    async function postServer(){
        setLoad(true)
        setReply('')
        if (data.length!==0) {
            console.log(data);
            try {
                const response = await RetailService.importFullOtchetRetail(data)
                console.log(response);
                setReply(response.data.message)

            } catch (e) {
                console.log(e);
                setReply(e.response?.data?.message + ' ' + e.response?.status)
            }
        } else {
            setReply('Внесите информацию во все пункты!')
        }
        setLoad(false)
    }



    return(

    <div className='box' style={{marginTop: 50}}>
        <div style={{marginTop: 10}}>
        <center><div style={{marginTop: 15}}> {load ? <span className="loader"/> : <>{reply}</>}</div></center>
        
        <center style={{marginTop: 25}}>
            <div class="input-group">
                <input
                    type="file"
                    className="form-control"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    onChange={(e) => {
                        setFileObject(e.target.files[0]);
                    }}
                    accept='.csv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                />

                <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="inputGroupFileAddon04"
                    onClick={postServer}>
                    Отправить
                </button>
            </div>
        </center>

        <table  className="table is-fullwidth" style={{marginTop: 10}}
            // cellspacing='0'
            // width='100%'
        >
            {/* {data[0]?.map((item) => ( */}
            <thead>
            <tr>
                {/* {data[0]?.map((head) => (
                <th>{head}</th>
                ))} */}
                <th> </th>
                <th> </th>
                <th> </th>
                <th> </th>
                <th> </th>
            </tr>
            </thead>
            {/* ))} */}
            {data?.map((item) => (
            <tbody>
                <tr>
                <td>{item?.Info}</td>
                <td>{item?.Product}</td>
                <td>{item?.Sku}</td>
                <td>{item?.Qt}</td>
                <td>{item?.Price}</td>
                </tr>
            </tbody>
            ))}
        </table>
        </div>
    </div>

    )
}