import $api from "../http";

export default class OzonService {
    
    
    static scanOzon(code){
        return $api.post('/ozon/scan', {code})
    }

    static sendFileOzon(data){
        return $api.post('/ozon/file', data, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
    }

}