import React, {useState} from 'react';

import UserService from "../../service/UserService";

export const WbCheck = () => {
    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')
    const [code, setCode] = useState('')
    
    function PressKey(event){
        if (!load && code && event.key === 'Enter'){
            Check()
        }
    }

    async function Check(){
        if (!load && code){
            setLoad(true)
            setReply('')

            try {
                const response = await UserService.fetchScanWbCheck(code)
                if (response.data.info) {
                    setReply(() => {
                        return(
                            <div>
                                <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">BarCode</th>
                                    <th scope="col">Дата заказа</th>
                                    <th scope="col">Поставка</th>
                                    <th scope="col">Товар</th>
                                    <th scope="col">SKU</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>{response.data.info.WbId}</td>
                                    <td>{response.data.info.WbBarCode}</td>
                                    <td>{response.data.info.WbDateOrder}</td>
                                    <td>{response.data.info.WbSupply}</td>
                                    <td>{response.data.info.KNameProduct}</td>
                                    <td>{response.data.info.WbSKU}</td>
                                    </tr>
                                </tbody>
                                </table>
                                <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">Статус WB</th>
                                    <th scope="col">Дата сканирования№1</th>
                                    <th scope="col">Сотрудник№1</th>
                                    <th scope="col">Дата сканирования№2</th>
                                    <th scope="col">Сотрудник№2</th>
                                    <th scope="col">Дата сканирования№3</th>
                                    <th scope="col">Сотрудник№3</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>{response.data.info.WbStatus}</td>
                                    <td>{response.data.info.DatePackage}</td>
                                    <td>{response.data.info.UserPackage}</td>
                                    <td>{response.data.info.DatePackage2}</td>
                                    <td>{response.data.info.UserPackage2}</td>
                                    <td>{response.data.info.DatePackage3}</td>
                                    <td>{response.data.info.UserPackage3}</td>
                                    </tr>
                                </tbody>
                                </table>

                            </div>
                        )
                    })
                }else{setReply(() => {return(<>{response.data.message} - {code}</>)})}      
            } catch (e) {
                setReply(e.response?.data?.message + ' ' + e.response?.status)
                console.log(e.response)
            }

            setLoad(false)
            setCode('')
        }   
    }

    return(
        <div>
            <div className='box' style={{marginTop: 50}}>
                <center><h1 className="fs-3">Проверка заказов WB</h1></center>
                <div className="input-group input-group-lg" style={{marginTop: 25}}>
                
                <input type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    onKeyUp={PressKey}
                    autoFocus={true}/>
                    <button type="button" className="btn btn-outline-secondary" onClick={Check}>Проверить</button>
                </div>

                <center><div style={{marginTop: 25}}> {load ? <span className="loader"/> : <>{reply}</>} </div></center>
            </div>
        </div>
    )
}