import React, {} from 'react';

export const ParsCenOtchet = () => {

    return (
        <div style={{marginTop: 50}}>
            <h1>Otchet</h1>
        </div>
    )

}
