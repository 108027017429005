import React, {} from 'react';
import MarkingService from "../../service/MarkingService";


export const MAdminFix = () => {

    async function Scan(){
        console.log('hi');
        const response = await MarkingService.testBro()
        console.log(response);
    }

    return (
        <div style={{marginTop: 50}}>
            hello
            <button type="button" className="btn btn-outline-primary" onClick={Scan} style={{marginLeft: 15}}>test</button>
        </div>
    )
}
