import $api from "../http";

export default class RetailService {

    static exportOtchetRetail(datamin,datamax){
        return $api.post('/exportOtchetRetail', {datamin,datamax})
    }

    static importFullOtchetRetail(data){
        const otchet = data
        return $api.post('/importFullOtchetRetail', {otchet})
    }

    static checkImport(date){
        return $api.post('/checkImport', {date})
    }

    
}