import { useState } from "react";
import AuthService from "../../service/AuthService";


export const Activated = () => {
    const [activatedCode, setActivatedCode] = useState('')
    const [Name2, setName2] = useState('')
    const [Name1, setName1] = useState('')
    const [r, setR] = useState('')

    async function activate(){

        if (activatedCode && Name2 && Name1) {
            try {
                const response = await AuthService.activated(activatedCode,Name2,Name1)
                console.log(response.data.message);
                if (response.data.message === "Успех") {
                    setR(() => {
                        return(
                            <div>
                                Успешно активирован
                                <a
                                type="button"
                                className="btn btn-success"
                                href="https://k.fo-den.ru/"
                                style={{marginLeft: 20}}>
                                    На главную
                                </a>
                            </div>
                        )
                    }) 
                }
            } catch (e) {
                console.log(e);
                setR('Ошибка')
            }
        }

    }

    return (

            <div style={{marginTop: 50}}>
                <div className="box">
                    <center>

                        <h1>Добро пожаловать в корпоративное приложение KApp</h1>

                        <h1 style={{marginTop: 10}}>ТРЕБУЕТСЯ АКТИВАЦИЯ ПРОФИЛЯ</h1>

                        <div className="row"  style={{marginTop: 20}}>

                        <div className="col">
                            <input type="text" className="form-control" placeholder="Имя" aria-label="Имя" value={Name1} onChange={e => setName1(e.target.value)}/>
                        </div>

                        <div className="col">
                            <input type="text" className="form-control" placeholder="Фамилия" aria-label="Фамилия" value={Name2} onChange={e => setName2(e.target.value)}/>
                        </div>

                        </div>

                        <div className="field has-addons" style={{marginTop: 10,}} >
                        <input
                            className="form-control"
                            style={{marginRight: 10}}
                            onChange={e => setActivatedCode(e.target.value)}
                            value={activatedCode}
                            type="text"
                            placeholder="Код активации."
                        />
                        <button
                        type="button"
                        className="btn btn-success"
                        onClick={activate}>
                            Активация
                        </button>
                                               
                        </div>


                        {r}
                    </center>
                </div>
            </div>

    )

}