import React, {useState, useContext, useEffect} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {NavLink} from 'react-router-dom';


const Navbar = () => {
    const [butDown, setButDown] = useState('')
    const {store} = useContext(Context);
    const accessPage = store.access
    //console.log(store.user.email);
      
    function bd(hr) {
        setButDown('')

        if (hr === 'wb' || (hr[0] === '/' && hr[1] === 'w' && hr[2] === 'b' && hr[3] === '/')) {
            setButDown(() =>{return(
                <nav className="navbar bg-body-tertiary" data-bs-theme="dark"> 
                <form className="container-fluid justify-content-start">
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/wb/check'} style={{marginLeft: 20}}> Проверка WB </NavLink> 
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/wb/scan'} style={{marginLeft: 10}}> Сканирование WB </NavLink>
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/wb/supply'} style={{marginLeft: 10}}> Поставки WB </NavLink>
                </form>
                </nav>
                )
                // <NavLink className="btn btn-sm btn-outline-secondary" to={'/wb/otchet'} style={{marginLeft: 10}}> Отчет WB </NavLink>
                // <NavLink className="btn btn-sm btn-outline-secondary" to={'/wb/analyst'} style={{marginLeft: 10}}> Аналитика WB </NavLink>
            })
        }

        if (hr === 'Marking' || (hr[0] === '/' && hr[1] === 'm' && hr[2] === 'a' && hr[3] === 'r')) {
            setButDown(() =>{return(
                <nav className="navbar bg-body-tertiary" data-bs-theme="dark"> 
                <form className="container-fluid justify-content-start">
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/marking/scan'} style={{marginLeft: 20}}> Сканирование ЧЗ </NavLink>
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/marking/scanmass'} style={{marginLeft: 10}}> Массовое сканирование ЧЗ </NavLink>
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/marking/otchet'} style={{marginLeft: 10}}> Отчет ЧЗ </NavLink>
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/marking/sell'} style={{marginLeft: 10}}> Продажа ЧЗ </NavLink>
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/marking/scandate'} style={{marginLeft: 10}}> Фикс ЧЗ </NavLink>
                </form>
                </nav>
                )
            })
        }

        if (hr === 'MarkingAdmin' || (hr[0] === '/' && hr[1] === 'm' && hr[2] === 'a' && hr[3] === 'r' && hr[4] === 'k' && hr[5] === 'i' && hr[5] === 'n' && hr[6] === 'g' && hr[7] === 'a')) {
            setButDown(() =>{return(
                <nav className="navbar bg-body-tertiary" data-bs-theme="dark"> 
                <form className="container-fluid justify-content-start">
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/markingadmin/fix'} style={{marginLeft: 20}}> Фикс </NavLink>
                </form>
                </nav>
                )
            })
        }

        if (hr === 'Retail' || (hr[0] === '/' && hr[1] === 'r' && hr[2] === 'e' && hr[3] === 't')) {
            setButDown(() =>{return(
                <nav className="navbar bg-body-tertiary" data-bs-theme="dark"> 
                <form className="container-fluid justify-content-start">
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/retail/import'} style={{marginLeft: 20}}> Импорт продаж </NavLink>
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/retail/otchet'} style={{marginLeft: 10}}> Отчет розница </NavLink>

                <NavLink className="btn btn-sm btn-outline-secondary" to={'/retail/check'} style={{marginLeft: 10}}> Фикс отчетов </NavLink>
                </form>
                </nav>
                )
            })
        }

        if (hr === 'BX24' || (hr[0] === '/' && hr[1] === 'b' && hr[2] === 'x' && hr[3] === '2')) {
            setButDown(() =>{return(
                <nav className="navbar bg-body-tertiary" data-bs-theme="dark"> 
                <form className="container-fluid justify-content-start">
                    
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/bx24/track'} style={{marginLeft: 20}}> Проверка трек номеров </NavLink>

                </form>
                </nav>
                )
            })
        }

        if (hr === 'Ozon' || (hr[0] === '/' && hr[1] === 'o' && hr[2] === 'z' && hr[3] === 'o')) {
            setButDown(() =>{return(
                <nav className="navbar bg-body-tertiary" data-bs-theme="dark"> 
                <form className="container-fluid justify-content-start">
                    
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/ozon/scan'} style={{marginLeft: 20}}> Ozon </NavLink>
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/ozon/1c'} style={{marginLeft: 20}}> 1c </NavLink>

                </form>
                </nav>
                )
            })
        }

        if (hr === 'ParsCen' || (hr[0] === '/' && hr[1] === 'p' && hr[2] === 'a' && hr[3] === 'r')) {
            setButDown(() =>{return(
                <nav className="navbar bg-body-tertiary" data-bs-theme="dark"> 
                <form className="container-fluid justify-content-start">
                    
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/parscen/add'} style={{marginLeft: 20}}> Добавление </NavLink>
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/parscen/otchet'} style={{marginLeft: 10}}> Отчет </NavLink>

                </form>
                </nav>
                )
            })
        }

        if (hr === 'QR' || (hr[0] === '/' && hr[1] === 'q' && hr[2] === 'r')) {
            setButDown(() =>{return(
                <nav className="navbar bg-body-tertiary" data-bs-theme="dark"> 
                <form className="container-fluid justify-content-start">
                    
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/qr/gen'} style={{marginLeft: 20}}> Генератор QR </NavLink>
                {/* <NavLink className="btn btn-sm btn-outline-secondary" to={'/parscen/otchet'} style={{marginLeft: 10}}> Отчет </NavLink> */}

                </form>
                </nav>
                )
            })
        }

        if (hr === 'Cert' || (hr[0] === '/' && hr[1] === 'c' && hr[2] === 'e')) {
            setButDown(() =>{return(
                <nav className="navbar bg-body-tertiary" data-bs-theme="dark"> 
                <form className="container-fluid justify-content-start">
                    
                <NavLink className="btn btn-sm btn-outline-secondary" to={'/cert'} style={{marginLeft: 20}}> Сертификаты </NavLink>
                {/* <NavLink className="btn btn-sm btn-outline-secondary" to={'/parscen/otchet'} style={{marginLeft: 10}}> Отчет </NavLink> */}

                </form>
                </nav>
                )
            })
        }

    }

    useEffect( ()=>{
        //console.log(window.location.pathname);
        let url = window.location.pathname
        bd(url)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    
    if(accessPage){
        let but = []
        for(let i = 0;accessPage.length>i;i++){
            if(accessPage[i] === 'Admin'){
                but.push({key:i, page: '👑', url: accessPage[i]})
            }
            if(accessPage[i] === 'wb'){
                but.push({key:i, page: 'Wildberries', url: accessPage[i]})
            }
            if(accessPage[i] === 'Marking'){
                but.push({key:i, page: 'Честный Знак', url: accessPage[i]})
            }
            if(accessPage[i] === 'MarkingAdmin'){
                but.push({key:i, page: 'ЧЗ Admin', url: accessPage[i]})
            }
            if(accessPage[i] === 'Retail'){
                but.push({key:i, page: 'Розница', url: accessPage[i]})
            }
            if(accessPage[i] === 'BX24'){
                but.push({key:i, page: 'BX24', url: accessPage[i]})
            }
            if(accessPage[i] === 'Ozon'){
                but.push({key:i, page: 'Ozon', url: accessPage[i]})
            }
            if(accessPage[i] === 'ParsCen'){
                but.push({key:i, page: 'Парсер цен', url: accessPage[i]})
            }
            if(accessPage[i] === 'QR'){
                but.push({key:i, page: 'QR', url: accessPage[i]})
            }
            if(accessPage[i] === 'Cert'){
                but.push({key:i, page: 'Сертификаты', url: accessPage[i]})
            }
        }
    

        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">                
                <div className="collapse navbar-collapse" id="navbarNavDropdown" style={{marginLeft: 20}}>
                    <ul className="navbar-nav">
                    {but.map(butGo =>
                        <li className="nav-item" key={butGo.key}> 
                            <NavLink className="nav-link"
                                style={{marginRight: 10}}
                                to={'/'+butGo.url}
                                onClick={() => bd(butGo.url)}
                                >
                                    {butGo.page}
                            </NavLink>
                        </li>
                    )}
                    </ul>
                </div>
                <form className="form-inline" style={{marginRight: 20}}>
                    <button className="btn btn-secondary" disabled={true} >{store.user.email}</button>
                    <button
                        style={{marginLeft: 10}}
                        className="btn btn-danger"
                        onClick={()=>store.logout()}>
                        Выход
                    </button>
                </form>
                </nav>
                {butDown}
            </div>
        )
    }
    
}

export default observer(Navbar);