import $api from "../http";

export default class UserService {
    // Admin
    static fetchUsers(){
        return $api.get('/users')
    }

    static postAccess(fun,acc,email){
        return $api.post('/postAccess', {fun,acc,email})
    }

    static postAccessPage(page,acc,email){
        return $api.post('/postAccessPage', {page,acc,email})
    }
    

    // Wb
    static fetchScanWbSupply(code){
        return $api.post('/scanWbSupply', {code})
    }

    static fetchScanWbOrder(code){
        return $api.post('/scanWbOrder', {code})
    }

    static fetchScanWbCheck(code){
        return $api.post('/scanWbCheck', {code})
    }
    
    static fetchMapSupplyInfo(supply){
        return $api.post('/mapSupplyInfo', {supply})
    }
    
    static fetchNewSupply(){
        return $api.post('/newSupply', {})
    }

    static fetchMapSupply(){
        return $api.post('/mapSupply', {})
    }

    static fetchEmailSupply(){
        return $api.post('/emailSupply', {})
    }
}


