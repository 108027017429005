import React, {useState} from 'react';
import MarkingService from "../../service/MarkingService";


export const MarkingSell = () => {
    const [load, setLoad] = useState(false)
    const [load1, setLoad1] = useState(false)

    const [action, setAction] = useState(false)

    const [reply, setReply] = useState('')
    const [reply1, setReply1] = useState('')
    const [code, setCode] = useState('')
    

    function PressKey(event){
        if (!load && !load1 && code && event.key === 'Enter'){
            Check()
        }
    }

    function Otmena(){
        setCode('')
        setReply('')
        setReply1('')
        setAction(false)
        setLoad(false)
        setLoad1(false)
    }

    async function Check(){
        if (!load && !load1 && code){
            setLoad(true)
            setLoad1(true)
            setReply('')

            try {
                const response = await MarkingService.checkMarkingSell(code)
                console.log(response.data)
    
                setReply(()=>{
                    return(
                        <div> 
                        <table className="table">
                            <thead>
                                <tr>
                                <th>Номер этикетки</th>
                                <th>Продукт</th>
                                <th>Владелец</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <th>{`${response.data.Block} - ${response.data.Number}`}</th>
                                <td>{response.data.Product}</td>
                                <td>{response.data.Owner}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    )
                })

                if (response.data.Counterparty === 'Академическая' || response.data.Counterparty === 'Тульская' || response.data.Counterparty === 'Депо' || response.data.Counterparty === 'Омега' || response.data.Counterparty === 'СПБ') {
                    
                    if (!response.data.Seller) {
                        setAction(true)
                    }else {
                        setCode('')
                        setLoad1(false)
                    }

                } else {
                    setCode('')
                    setLoad1(false)
                }

            } catch (e) {
                console.log(e);
                setLoad1(false)
                setReply(e.response?.data?.message + ' ' + e.response?.status)
                setCode('')
            } finally{
                setLoad(false)
            }       
        }   
    }

    async function ConfirmSell(){

            console.log(code)
            setLoad(true)
            setReply1('')
            try {
                const response = await MarkingService.markingSell({code})
                console.log(response)
                setReply('Обновлен')
                
                setCode('')
                setReply1('')
                setAction(false)
                setLoad(false)
                setLoad1(false)
            } catch (e) {
                console.log(e);
                setReply(e.response?.data?.message + ' ' + e.response?.status)
                
                setCode('')
                setReply1('')
                setAction(false)
                setLoad(false)
                setLoad1(false)
            } 
         
    }


    const otpravkaZakaza = () => {
        return(
            <div>
            <center>
            <div style={{marginTop: 25}}>
                <button type="button" className="btn btn-outline-success" onClick={ConfirmSell} disabled={load}>Продажа</button>
                <button type="button" className="btn btn-outline-danger" style={{marginLeft: 25}} onClick={Otmena} disabled={load}>Отмена</button>
            </div>
            </center>
            {reply1}

            </div>
        )
    }

    return(
        <div>
            <div className='box' style={{marginTop: 50}}>
                <center><h1 className="fs-3">Продажа товаров с маркировкой</h1></center>
                <div className="input-group input-group-lg" style={{marginTop: 25}}>
                
                <input type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    onKeyUp={PressKey}
                    autoFocus={true}
                    disabled={load1}/>
                    <button type="button" className="btn btn-outline-secondary" onClick={Check} disabled={load1}>Проверить</button>
                </div>

                <center>
                    <div style={{marginTop: 25}}> {load ? <span className="loader"/> : <>{reply}</>}</div>
                    <div>{action ? otpravkaZakaza() : ""}</div>
                </center>
            </div>
        </div>
    )
}