import React, {} from 'react';
import {Redirect} from 'react-router-dom'


export const WbHome = () => {

  
    return(
        <div>
            <Redirect to="/wb/check" />
        </div>
    )
}