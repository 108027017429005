import React, {useState, useEffect} from 'react';
import UserService from "../../service/UserService";

export const Admin = () => {
    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')

    useEffect( ()=>{
        GetUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function PostAccess(fun,acc,email){
        const response = await UserService.postAccess(fun,acc,email)
        console.log(response);
        
        const response1 = await UserService.fetchUsers()
        let access = response1.data.access

        for (let i = 0; i < access.length; i++) {
            if (access[i].email === email) {
                GetInfoUser(access[i])
            }
        }
        
    }

    async function PostAccessPage(page,acc,email){
        const response = await UserService.postAccessPage(page,acc,email)
        console.log(response);
        
        const response1 = await UserService.fetchUsers()
        let access = response1.data.access

        for (let i = 0; i < access.length; i++) {
            if (access[i].email === email) {
                GetInfoUser(access[i])
            }
        }
        
    }
    
    function GetInfoUser(users){
        const accessFunctionALL = [
            // Admin
            {fun: 'getUsers', ru: 'Получить информацию и пользователях'},
            {fun: 'postAccess', ru: 'Управление доступом функций'},
            {fun: 'postAccessPage', ru: 'Управление доступом страниц'},

            // Wb
            {fun: 'scanWbSupply', ru: 'Сканирование поставки WB'},
            {fun: 'scanWbOrder', ru: 'Сканирование заказа WB'},
            {fun: 'mapSupply', ru: 'Получение информации по поставкам WB'},
            {fun: 'mapSupplyInfo', ru: 'Получение подробной информации по поставке WB'},
            {fun: 'newSupply', ru: 'Автопоставка WB'},
            {fun: 'checkWbOrder', ru: 'Проверка заказа WB'},

            // ЧЗ
            {fun: 'checkMarking', ru: 'Сканирование ЧЗ - СКЛАД'},
            {fun: 'checkMarkingSell', ru: 'Сканирование ЧЗ - ШОУРУМ'},
            {fun: 'markingSell', ru: 'Продажа ЧЗ'},
            {fun: 'confirmMarking', ru: 'Подкрепление заказа к ЧЗ'},
            {fun: 'confirmMarkingDate', ru: 'Подкрепление заказа к ЧЗ с своей датой'},
            {fun: 'otchetMarking', ru: 'Отчет ЧЗ - по дате'},
            {fun: 'otchetMarkingid', ru: 'Отчет ЧЗ - по ID'},
            {fun: 'returnMarking', ru: 'Ликведация ЧЗ - склад'},
            {fun: 'delConterMarking', ru: 'Возврат ЧЗ - склад'},
            
            // Разработка
            {fun: 'emailSupply', ru: 'Почты для отправки информации по WB'}, // WB - В разработке

            // Розница
            {fun: 'importFullOtchetRetail', ru: 'Полный импорт смены по рознице'},
            {fun: 'checkImport', ru: 'Проверка импорта'},
            
            {fun: 'exportOtchetRetail', ru: 'Экспорт отчета продаж за смену по рознице'},
            
            //bx24
            {fun: 'trackBx24', ru: 'Трек заказов BX24'},

            //ozon
            {fun: 'scanOzon', ru: 'Сканирование OZON'},
            {fun: 'fileOzon', ru: 'импорт Файл OZON для 1с'},
            
            
            //pars
            {fun: 'addStorePars', ru: 'Добавление магазина парсер'},
            {fun: 'getStoresPars', ru: 'Получение магазинов парсер'},
            {fun: 'delStoresPars', ru: 'Удаление магазина парсер'},
            {fun: 'addItemPars', ru: 'Добавить товар в парсер'},
            {fun: 'getItemsPars', ru: 'Получение товаров парсер'},
            {fun: 'delItemPars', ru: 'Удаление товара парсер'},

            //QR
            {fun: 'genQr', ru: 'Генератор QR'},
            
            //cert
            {fun: 'checkCert', ru: 'Проверка сертификата'},
            
        ]
        
        const accessPageALL = [
            {page: 'Admin', ru: 'Админ панель'},
            {page: 'wb', ru: 'Wildberries'},
            {page: 'Marking', ru: 'Честный Знак'},
            {page: 'Retail', ru: 'Розница'},
            {page: 'BX24', ru: 'BX24'},
            {page: 'MarkingAdmin', ru: 'ЧЗ Админ'},
            {page: 'Ozon', ru: 'OZON'},
            {page: 'ParsCen', ru: 'Парсер Цен'},
            {page: 'QR', ru: 'QR'},
            {page: 'Cert', ru: 'Сертификаты'},
        ]
        
        let accessFunctionUser = []
        for (let i = 0; i < accessFunctionALL.length; i++) {
            let acc = false
            for (let o = 0; o < users.accessFunction.length; o++) {
                if (accessFunctionALL[i].fun === users.accessFunction[o]) {
                    acc = true
                    break
                }
            }
            accessFunctionUser.push({fun: accessFunctionALL[i].fun,ru: accessFunctionALL[i].ru, acc})
        }

        let accessPageUser = []
        for (let i = 0; i < accessPageALL.length; i++) {
            let acc = false
            for (let o = 0; o < users.accessPage.length; o++) {
                if (accessPageALL[i].page === users.accessPage[o]) {
                    acc = true
                    break
                }
            }
            accessPageUser.push({page: accessPageALL[i].page,ru: accessPageALL[i].ru, acc})
        }


        setReply(()=>{
            return(
                <div>
                    <button onClick={GetUsers} className="btn btn-secondary btn-sm">Назад</button>
                    <p>{users.Name1} {users.Name2}</p>

                    <table className="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">Страница</th>
                        <th scope="col">Доступность</th>
                        <th scope="col">Настройки</th>
                        </tr>
                    </thead>
                    <tbody>
                    {accessPageUser.map(user =>
                        <tr key={user.page}>
                        <td>{user.ru}</td>
                        <td>{user.acc ? '✅' : '❌'}</td>
                        <td>{user.acc ? 
                        <button className="btn btn-outline-danger btn-sm" onClick={()=>{PostAccessPage(user.page, !user.acc, users.email)}}>Забрать доступ</button> :
                        <button className="btn btn-outline-success btn-sm" onClick={()=>{PostAccessPage(user.page, !user.acc, users.email)}}>Выдать доступ</button>} </td>
                        </tr>
                    )}
                    </tbody>
                    </table>
                    
                    <table className="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">Функционал</th>
                        <th scope="col">Доступность</th>
                        <th scope="col">Настройки</th>
                        </tr>
                    </thead>
                    <tbody>
                    {accessFunctionUser.map(user =>
                        <tr key={user.fun}>
                        <td>{user.ru}</td>
                        <td>{user.acc ? '✅' : '❌'}</td>
                        <td>{user.acc ? 
                        <button className="btn btn-outline-danger btn-sm" onClick={()=>{PostAccess(user.fun, !user.acc, users.email)}}>Забрать доступ</button> :
                        <button className="btn btn-outline-success btn-sm" onClick={()=>{PostAccess(user.fun, !user.acc, users.email)}}>Выдать доступ</button>} </td>
                        </tr>
                    )}
                    </tbody>
                    </table>
                    
                    <button onClick={GetUsers} className="btn btn-secondary btn-sm">Назад</button>
                </div>
                
            )
        })
    }

    async function GetUsers(){
        setLoad(true)
        try {
            const response = await UserService.fetchUsers()
            let access = response.data.access
            setReply(()=>{
                return(
                    <table className="table table-hover">
                    <thead>
                        <tr>
                        <th scope="col">EMail</th>
                        <th scope="col">Имя</th>
                        <th scope="col">Фамилия</th>
                        </tr>
                    </thead>
                    <tbody>
                    {access.map(users =>
                        <tr key={users._id} onClick={()=>{GetInfoUser(users)}}>
                        <td>{users.email}</td>
                        <td>{users.Name1}</td>
                        <td>{users.Name2}</td>
                        </tr>
                    )}
                    </tbody>
                    </table>
                )
            })
        } catch (e) {
            setReply(e.response?.data?.message + ' ' + e.response?.status)
            console.log(e.response)
        }
        setLoad(false)
    }



    return(
        <div>
            <div className='box' style={{marginTop: 50}}>
                <center><div style={{marginTop: 25}}>{load ? <span className="loader"></span> : <>{reply}</>}</div></center>
            </div>
        </div>
    )

}

