import React, {} from 'react';
import {Redirect} from 'react-router-dom'

export const Marking = () => {

    return (
            <div style={{marginTop: 50}}>
                <Redirect to="/marking/scan" />
            </div>
    )
}
