import React, {useState, useEffect} from 'react';
import RetailService from "../../service/RetailService";

export const RetailCheck = () => {
    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')

    useEffect( ()=>{
        //Сheck()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function Сheck(){
        setLoad(true)
        try {
            const now = new Date();
            const date = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate()
            
            const response = await RetailService.checkImport(date)
            console.log(response);
        } catch (e) {
            console.log(e);
            setReply(e.response?.data?.message + ' ' + e.response?.status)
        }
        setLoad(false)
    }

    return(
    <div className='box' style={{marginTop: 50}}>

        <div style={{marginTop: 10}}>

        <button
            className="btn btn-outline-secondary"
            type="button"
            id="inputGroupFileAddon04"
            onClick={Сheck}>
            Отправить
        </button>

        <center><div style={{marginTop: 25}}> {load ? <span className="loader"/> : <>{reply}</>}</div></center>
        </div>

    </div>

    )
}