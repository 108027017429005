import React, {} from 'react';

export const WbAnalyst = () => {

  
    return(
        <div style={{marginTop: 50}}>
                <h1>Временно без учета архива</h1>
                <iframe
                style={{marginTop: 10}}
                title="GoogleStudio"
                width={1200}
                height={900}
                src="https://lookerstudio.google.com/embed/reporting/8fb1157a-014e-4b49-aff6-f948bb06d5fb/page/nm3GD">
                </iframe>
           
        </div>
    )
}