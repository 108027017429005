import React, {useState, useEffect} from 'react';
import MarkingService from "../../service/MarkingService";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export const MarkingOtchet = () => {
    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')
    const [datamin, setDatamin] = useState('')
    const [datamax, setDatamax] = useState('')
    const [fulcode, setFulcode] = useState(false)
    const [contragent, setContragent] = useState('')
    
    const now = new Date();
    const nowDayISO = now.toISOString()
    let nowDay = ''
    for (let i = 0; i < nowDayISO.length; i++) {
        if (nowDayISO[i] === 'T') {
            break
        }
        nowDay = nowDay+nowDayISO[i]
    }
    
    useEffect( ()=>{
        setDatamin(nowDay)
        setDatamax(nowDay)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    async function otchet(){
        console.log(datamin);
        console.log(datamax);
        
        setLoad(true)

        try {
            const response = await MarkingService.fetchOtchetMarking({datamin, datamax})
            console.log(response)
            if (response.data.info) {

                let KAORI = []
                let KORENCHUK = []
                let OKUNEV = []
                let NO_OWNER = []

                for (let i = 0; i < response.data.info.length; i++) {
                    if (response.data.info[i].Owner === 'КАОРИ') {
                        KAORI.push(response.data.info[i])
                    }
                    if (response.data.info[i].Owner === 'Коренчук') {
                        KORENCHUK.push(response.data.info[i])
                    }
                    if (response.data.info[i].Owner === 'Окунев') {
                        OKUNEV.push(response.data.info[i])
                    }
                    if (response.data.info[i].Owner !== 'КАОРИ' && response.data.info[i].Owner !== 'Коренчук' && response.data.info[i].Owner !== 'Окунев') {
                        NO_OWNER.push(response.data.info[i])
                    }
                }

                setReply(()=>{
                    return(
                        <div className="notification" style={{marginTop: 10}}>
                            

                            <ReactHTMLTableToExcel
                            className="btn btn-outline-secondary btn-sm"
                            table="NO_OWNER-table-to-xls"
                            filename="NO_OWNER-Marking"
                            sheet="NO_OWNER-Marking"
                            buttonText="Скачать в XLS"/>
                            <label style={{marginLeft: 10}}> - Не определен</label>
                            <table className="table is-fullwidth" id="NO_OWNER-table-to-xls" style={{marginTop: 10}}>
                                    <thead>
                                        <tr>
                                        <th>Продукт</th>
                                        <th>Код</th>
                                        <th>Владелец</th>
                                        <th>Контрагент</th>
                                        <th>Дата изменения</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {NO_OWNER.map(info =>
                                            <tr key={info.Code}>
                                            <td>{info.Product}</td>
                                            <td>{fulcode ? <>{info.Code_full}</> : <>{info.Code}</>}</td>
                                            <td>{info.Owner}</td>
                                            <td>{info.Counterparty}</td>
                                            <td>{info.Date}</td>
                                            </tr>
                                        )}
        
                                    </tbody>
                                </table>


                            <ReactHTMLTableToExcel
                            className="btn btn-outline-secondary btn-sm"
                            table="kaori-table-to-xls"
                            filename="KAORI-Marking"
                            sheet="KAORI-Marking"
                            buttonText="Скачать в XLS"/>
                            <label style={{marginLeft: 10}}> - КАОРИ</label>
                            <table className="table is-fullwidth" id="kaori-table-to-xls" style={{marginTop: 10}}>
                                    <thead>
                                        <tr>
                                        <th>Продукт</th>
                                        <th>Код</th>
                                        <th>Владелец</th>
                                        <th>Контрагент</th>
                                        <th>Дата изменения</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {KAORI.map(info =>
                                            <tr key={info.Code}>
                                            <td>{info.Product}</td>
                                            <td>{fulcode ? <>{info.Code_full}</> : <>{info.Code}</>}</td>
                                            <td>{info.Owner}</td>
                                            <td>{info.Counterparty}</td>
                                            <td>{info.Date}</td>
                                            </tr>
                                        )}
        
                                    </tbody>
                                </table>
                                
                                
                                <ReactHTMLTableToExcel
                                className="btn btn-outline-secondary btn-sm"
                                table="korenchuk-table-to-xls"
                                filename="KORENCHUK-Marking"
                                sheet="KORENCHUK-Marking"
                                buttonText="Скачать в XLS"/>

                                <label style={{marginLeft: 10}}> - Коренчук</label>
                                <table className="table is-fullwidth" id="korenchuk-table-to-xls" style={{marginTop: 10}}>
                                        <thead>
                                            <tr>
                                            <th>Продукт</th>
                                            <th>Код</th>
                                            <th>Владелец</th>
                                            <th>Контрагент</th>
                                            <th>Дата изменения</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {KORENCHUK.map(info =>
                                                <tr key={info.Code}>
                                                <td>{info.Product}</td>
                                                <td>{fulcode ? <>{info.Code_full}</> : <>{info.Code}</>}</td>
                                                <td>{info.Owner}</td>
                                                <td>{info.Counterparty}</td>
                                                <td>{info.Date}</td>
                                                </tr>
                                            )}
            
                                        </tbody>
                                    </table>

                                

                                <ReactHTMLTableToExcel
                                className="btn btn-outline-secondary btn-sm"
                                table="okunev-table-to-xls"
                                filename="OKUNEV-Marking"
                                sheet="OKUNEV-Marking"
                                buttonText="Скачать в XLS"/>
                                <label style={{marginLeft: 10}}> - Окунев</label>
                                <table className="table is-fullwidth" id="okunev-table-to-xls" style={{marginTop: 10}}>  
                                        <thead>
                                            <tr>
                                            <th>Продукт</th>
                                            <th>Код</th>
                                            <th>Владелец</th>
                                            <th>Контрагент</th>
                                            <th>Дата изменения</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {OKUNEV.map(info =>
                                                <tr key={info.Code}>
                                                <td>{info.Product}</td>
                                                <td>{fulcode ? <>{info.Code_full}</> : <>{info.Code}</>}</td>
                                                <td>{info.Owner}</td>
                                                <td>{info.Counterparty}</td>
                                                <td>{info.Date}</td>
                                                </tr>
                                            )}
            
                                        </tbody>
                                    </table>
                        </div> 
                    )
                })
            } else {
                setReply(()=>{
                    return(
                        <p>Изменений не найдено</p>
                    )
                })
            }
            
            
        } catch (e) {
            console.log(e);
            setReply(e.response?.data?.message + ' ' + e.response?.status)
        } 

        setLoad(false)
    }

    async function otchetID(){
        setLoad(true)
        if (!contragent) {
            setLoad(false)
            return (
                setReply('Укажите контрагента')
            )
        }

        try {
            const response = await MarkingService.fetchOtchetMarkingId({contragent})
            console.log(response)
            if (response.data.info) {

                let KAORI = []
                let KORENCHUK = []
                let OKUNEV = []
                let NO_OWNER = []

                for (let i = 0; i < response.data.info.length; i++) {
                    if (response.data.info[i].Owner === 'КАОРИ') {
                        KAORI.push(response.data.info[i])
                    }
                    if (response.data.info[i].Owner === 'Коренчук') {
                        KORENCHUK.push(response.data.info[i])
                    }
                    if (response.data.info[i].Owner === 'Окунев') {
                        OKUNEV.push(response.data.info[i])
                    }
                    if (response.data.info[i].Owner !== 'КАОРИ' && response.data.info[i].Owner !== 'Коренчук' && response.data.info[i].Owner !== 'Окунев') {
                        NO_OWNER.push(response.data.info[i])
                    }
                }

                setReply(()=>{
                    return(
                        <div className="notification" style={{marginTop: 10}}>
                            
                            <ReactHTMLTableToExcel
                            className="btn btn-outline-secondary btn-sm"
                            table="NO_OWNER-table-to-xls"
                            filename="NO_OWNER-Marking"
                            sheet="NO_OWNER-Marking"
                            buttonText="Скачать в XLS"/>
                            <label style={{marginLeft: 10}}> - Не определен</label>
                            <table className="table is-fullwidth" id="NO_OWNER-table-to-xls" style={{marginTop: 10}}>
                                    <thead>
                                        <tr>
                                        <th>Продукт</th>
                                        <th>Код</th>
                                        <th>Владелец</th>
                                        <th>Контрагент</th>
                                        <th>Дата изменения</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {NO_OWNER.map(info =>
                                            <tr key={info.Code}>
                                            <td>{info.Product}</td>
                                            <td>{fulcode ? <>{info.Code_full}</> : <>{info.Code}</>}</td>
                                            <td>{info.Owner}</td>
                                            <td>{info.Counterparty}</td>
                                            <td>{info.Date}</td>
                                            </tr>
                                        )}
        
                                    </tbody>
                                </table>


                                <ReactHTMLTableToExcel
                                className="btn btn-outline-secondary btn-sm"
                                table="kaori-table-to-xls"
                                filename="KAORI-Marking"
                                sheet="KAORI-Marking"
                                buttonText="Скачать в XLS"/>
                                <label style={{marginLeft: 10}}> - КАОРИ</label>
                                <table className="table is-fullwidth" id="kaori-table-to-xls" style={{marginTop: 10}}>
                                        <thead>
                                            <tr>
                                            <th>Продукт</th>
                                            <th>Код</th>
                                            <th>Владелец</th>
                                            <th>Контрагент</th>
                                            <th>Дата изменения</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {KAORI.map(info =>
                                                <tr key={info.Code}>
                                                <td>{info.Product}</td>
                                                <td>{fulcode ? <>{info.Code_full}</> : <>{info.Code}</>}</td>
                                                <td>{info.Owner}</td>
                                                <td>{info.Counterparty}</td>
                                                <td>{info.Date}</td>
                                                </tr>
                                            )}
            
                                        </tbody>
                                    </table>


                                
                                <ReactHTMLTableToExcel
                                className="btn btn-outline-secondary btn-sm"
                                table="korenchuk-table-to-xls"
                                filename="KORENCHUK-Marking"
                                sheet="KORENCHUK-Marking"
                                buttonText="Скачать в XLS"/>

                                <label style={{marginLeft: 10}}> - Коренчук</label>
                                <table className="table is-fullwidth" id="korenchuk-table-to-xls" style={{marginTop: 10}}>
                                        <thead>
                                            <tr>
                                            <th>Продукт</th>
                                            <th>Код</th>
                                            <th>Владелец</th>
                                            <th>Контрагент</th>
                                            <th>Дата изменения</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {KORENCHUK.map(info =>
                                                <tr key={info.Code}>
                                                <td>{info.Product}</td>
                                                <td>{fulcode ? <>{info.Code_full}</> : <>{info.Code}</>}</td>
                                                <td>{info.Owner}</td>
                                                <td>{info.Counterparty}</td>
                                                <td>{info.Date}</td>
                                                </tr>
                                            )}
            
                                        </tbody>
                                    </table>

                                

                                <ReactHTMLTableToExcel
                                className="btn btn-outline-secondary btn-sm"
                                table="okunev-table-to-xls"
                                filename="OKUNEV-Marking"
                                sheet="OKUNEV-Marking"
                                buttonText="Скачать в XLS"/>
                                <label style={{marginLeft: 10}}> - Окунев</label>
                                <table className="table is-fullwidth" id="okunev-table-to-xls" style={{marginTop: 10}}>  
                                        <thead>
                                            <tr>
                                            <th>Продукт</th>
                                            <th>Код</th>
                                            <th>Владелец</th>
                                            <th>Контрагент</th>
                                            <th>Дата изменения</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {OKUNEV.map(info =>
                                                <tr key={info.Code}>
                                                <td>{info.Product}</td>
                                                <td>{fulcode ? <>{info.Code_full}</> : <>{info.Code}</>}</td>
                                                <td>{info.Owner}</td>
                                                <td>{info.Counterparty}</td>
                                                <td>{info.Date}</td>
                                                </tr>
                                            )}
            
                                        </tbody>
                                    </table>
                        </div> 
                    )
                })
            } else {
                setReply(()=>{
                    return(
                        <p>Изменений не найдено</p>
                    )
                })
            }
            
            
        } catch (e) {
            console.log(e);
            setReply(e.response?.data?.message + ' ' + e.response?.status)
        } 

        setLoad(false)
    }

    function FulCode(){
        if (fulcode) {
            setFulcode(false)
        }
        if (!fulcode) {
            setFulcode(true)
        }
    }

    return(

    <div className='box' style={{marginTop: 50}}>
        
        <div className="row g-3" style={{marginTop: 10}}>
        
        <div className="col-sm-2">
        <input
            type="date"
            id="bday"
            name="bday"
            value={datamin}
            onChange={e => setDatamin(e.target.value)}
            style={{marginLeft: 10}}
            className="form-control" 
        />
        </div>

        <div className="col-sm-2">
        <input
            type="date"
            id="bday"
            name="bday"
            value={datamax}
            onChange={e => setDatamax(e.target.value)}
            style={{marginLeft: 10}}
            className="form-control" 
        />
        </div>

        <div className="col-sm-3">
        <button
            type="button"
            onClick={otchet}
            style={{marginLeft: 10}}
            className="btn btn-outline-secondary">
                Сформировать отчет по дате
        </button>
        </div>

        <div className="col-sm-3"> {fulcode 
            
            ? <button
                type="button"
                onClick={FulCode}
                style={{marginLeft: 10}}
                className="btn btn-outline-secondary">
                    Выключить полный код
            </button>

            : <button
                type="button"
                onClick={FulCode}
                style={{marginLeft: 10}}
                className="btn btn-outline-secondary">
                    Включить полный код
            </button>

        }</div>
        
        </div>

        <div className="row g-3" style={{marginTop: 5}}>
            
            <div className="col-sm-4">
            <input
                type="text"
                onChange={e => setContragent(e.target.value)}
                value={contragent}
                style={{marginLeft: 10}}
                className="form-control" 
            />
            </div>

            <div className="col-sm-3">
            <button
                type="button"
                onClick={otchetID}
                style={{marginLeft: 10}}
                className="btn btn-outline-secondary">
                    Сформировать отчет по контрагенту
            </button>
            </div>

        </div>

        <div style={{marginTop: 25}}> { load ? <center><span className="loader"/></center> : <>{reply}</>}</div>

     </div>

    )
}