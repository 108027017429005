import React, {useState, useEffect} from 'react';
import RetailService from "../../service/RetailService";

export const RetailOtchet = () => {
    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')
    const [datamin, setDatamin] = useState('')
    const [datamax, setDatamax] = useState('')

    const [shop, setShop] = useState('')
    const [manager, setManager] = useState('')
    const [product, setProduct] = useState('')

    const now = new Date();
    const nowDayISO = now.toISOString()
    let nowDay = ''
    for (let i = 0; i < nowDayISO.length; i++) {
        if (nowDayISO[i] === 'T') {
            break
        }
        nowDay = nowDay+nowDayISO[i]
    }
    
    useEffect( ()=>{
        setDatamin(nowDay)
        setDatamax(nowDay)
        otchet()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    async function otchet(){
        setLoad(true)
        try {
            const response = await RetailService.exportOtchetRetail(datamin,datamax)
            console.log(response);

            if (!response.data.otchet) {
                setLoad(false)
                return setReply(response.data.message)
            }

            setShop(()=>{
                return(
                    <>
                    <label style={{marginTop: 20}}>Отчет по точкам продаж:</label>
                    <table className="table is-fullwidth" style={{marginTop: 10}}>
                    <thead>
                        <tr>
                        <th>Месяц</th>
                        <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {response.data.otchet.map(info => 
                            <tr>
                                <td>{info.mounth}</td>
                                <td>
                                <thead>
                                    <tr>
                                    <th>Точка продаж</th>
                                    <th>Сумма продаж</th>
                                    <th>Прибыль</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {info.otchet.map(otchet => {return(
                                    <tr>
                                    <td>{otchet.shop}</td>
                                    <td>{Math.round(otchet.total_price_U)}₽</td>
                                    <td>{Math.round(otchet.profit_commissionCard_opt)}₽</td>
                                    </tr>
                                )})}
                                </tbody>
                                </td>
                            </tr>
                        )}
                    </tbody>
                    </table>
                    </>
                )
            })
            
            setManager(()=>{
                return(
                    <>
                    <label style={{marginTop: 20}}>Расчет по менеджерам:</label>
                    <table className="table is-fullwidth" id="kaori-table-to-xls" style={{marginTop: 10}}>
                        <thead>
                            <tr>
                            <th>Месяц</th>
                            <th> </th>
                            </tr>
                        </thead>
                        <tbody>
                        {response.data.otchet.map(info => 
                            <tr>
                                <td>{info.mounth}</td>
                                <td>
                                <thead>
                                    <tr>
                                    <th>Точка продаж</th>
                                    <th>Сотрудник</th>
                                    <th>Сумма продаж</th>
                                    <th>Время смен</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {info.managers.map(managers => {return(
                                    <tr>
                                    <td>{managers.Shop}</td>
                                    <td>{managers.Manager}</td>
                                    <td>{managers.Selling}₽</td>
                                    <td>{managers.TimeSmena}</td>
                                    </tr>
                                )})}
                                </tbody>
                                </td>
                            </tr>
                        )}
                    </tbody>
                    </table>
                    </>
                )
            })
            
            setProduct(()=>{
                return(
                    <>
                    <label style={{marginTop: 20}}>Товары у которых нету опт цены или себестоимости:</label>
                    <table className="table is-fullwidth" id="kaori-table-to-xls" style={{marginTop: 10}}>
                        <thead>
                            <tr>
                            <th>Месяц</th>
                            <th> </th>
                            </tr>
                        </thead>

                        <tbody>
                        {response.data.otchet.map(info => 
                            <tr>
                                <td>{info.mounth}</td>
                                <td>
                                <thead>
                                    <tr>
                                    <th>Товар</th>
                                    <th>Сумма продаж</th>
                                    <th>Опт цена</th>
                                    <th>Себес цена</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {info.problemProduct.map(problemProduct => {return(
                                    <tr>
                                    <td>{problemProduct.product}</td>
                                    <td>{problemProduct.total_price}₽</td>
                                    <td>{problemProduct.PriceOpt2}₽</td>
                                    <td>{problemProduct.PriceSebes}₽</td>
                                    </tr>
                                )})}
                                </tbody>
                                </td>
                            </tr>
                        )}
                    </tbody>
                    </table>
                    </>
                )
            })
            
        } catch (e) {
            console.log(e);
            setReply(e.response?.data?.message + ' ' + e.response?.status)
        }
        setLoad(false)
    }

    function Shop() {
        setReply(shop)
    }
    function Manager() {
        setReply(manager)
    }
    function Product() {
        setReply(product)
    }

    return(

        <div className='box' style={{marginTop: 50}}>
        
        <div style={{marginTop: 10}} >
            <button
                type="button"
                onClick={Shop}
                style={{marginLeft: 10}}
                className="btn btn-outline-secondary btn-sm">
                    Отчет по точкам продаж
            </button>

            <button
                type="button"
                onClick={Manager}
                style={{marginLeft: 10}}
                className="btn btn-outline-secondary btn-sm">
                    Отчет сотрудники
            </button>
            
            <button
                type="button"
                onClick={Product}
                style={{marginLeft: 10}}
                className="btn btn-outline-secondary btn-sm">
                    Товары с ошибками
            </button>

            <button
                type="button"
                onClick={otchet}
                style={{marginLeft: 30}}
                className="btn btn-outline-secondary btn-sm">
                    Обновить
            </button>
            
            
        </div>

        <div style={{marginTop: 25}}><center> {load ? <span className="loader"/> : <div>{reply}</div>} </center></div>

        </div>

    )
}
