import React, {} from 'react';
import {Redirect} from 'react-router-dom'

export const Ozon = () => {

    return (
            <div style={{marginTop: 50}}>
                <Redirect to="/ozon/scan" />
            </div>
    )


}
