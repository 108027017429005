import $api from "../http";

export default class MarkingService {

    // Сканирование ЧЗ
    static fetchCheckMarking(code){
        return $api.post('/checkMarking', {code})
    }

    // Сканирование ЧЗ - шоурум
    static checkMarkingSell(code){
        return $api.post('/checkMarkingSell', {code})
    }

    // Продажа ЧЗ
    static markingSell(code){
        return $api.post('/markingSell', code)
    }

    
    // Подтверждение отгрузки ЧЗ 
    static fetchConfirmMarking({code, order}){
        return $api.post('/confirmMarking', {code, order})
    }

    // Подтверждение отгрузки ЧЗ - с пресетом
    static fetchConfirmMarkingPreset({code, preset}){
        const order = preset
        return $api.post('/confirmMarking', {code, order})
    }

    // Подтверждение отгрузки ЧЗ - с указанием даты
    static fetchConfirmMarkingDate({code, order, date}){
        return $api.post('/confirmMarkingDate', {code, order, date})
    }

    // Отчет ЧЗ - по дате
    static fetchOtchetMarking({datamin, datamax}){
        return $api.post('/otchetMarking', {datamin, datamax})
    }

    static fetchOtchetMarkingId({contragent}){
        return $api.post('/otchetMarkingid', {contragent})
    }

    // Ликведация ЧЗ - склад
    static fetchReturnMarking({codeToReturn}){
        return $api.post('/returnMarking', {codeToReturn})
    }

    // Возврат ЧЗ - склад (удаление контрагента)
    static fetchDelConterMarking({codeToReturn}){
        return $api.post('/delConterMarking', {codeToReturn})
    }


    static testBro(){
        return $api.post('/testBx24')
    }

    
}