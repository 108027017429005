import React, {useState} from 'react';

import UserService from "../../service/UserService";

export const Wbscan = () => {
    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')
    const [code, setCode] = useState('')
    

    async function Scan(event){
        if (!load && code){
            if (event.key === 'Enter'){
                setLoad(true)
                setReply('')
                if(code[0] === '*'){
                    
                    try {
                        const response = await UserService.fetchScanWbOrder(code)
                        console.log(response);
                        setReply(() => {return(<>{response.data.message}<br/>{response.data.emoji}<br/>{response.data.info}<br/>{response.data.product}</>)})
                    } catch (e) {
                        setReply(e.response?.data?.message + ' ' + e.response?.status)
                    }
                    
                } else {
                    setReply(() => {return(<>QR - Не опознан<br/>🗿 🗿 🗿 🗿 🗿</>)})
                }
                setLoad(false)
                setCode('')
            }
        }   
    }


    return(
        <div>
            <div className='box' style={{marginTop: 50}}>
                <center><h1 className="fs-3">Сканирование WB</h1></center>
                <div className="input-group input-group-lg" style={{marginTop: 25}}>
                
                <span className="input-group-text" id="inputGroup-sizing-lg">Сканирование</span>
                
                <input type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    onKeyUp={Scan}
                    autoFocus={true}/>
                </div>
                <center><div style={{marginTop: 25}}>{load ? <span className="loader"></span> : <p className="fs-1">{reply}</p>}</div></center>
            </div>
        </div>
    )
}