import React, {useState, useEffect} from 'react';
import QrService from "../../service/QrService";

export const QrGen = () => {

    // useEffect( ()=>{
    //     getQr()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[])

    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')

    const [textForQr, setTextForQr] = useState('')
    const [qtForPdf, setQtForPdf] = useState('')

    const [qrArray, setQrArray] = useState('')


    async function QrGen(){
        setQrArray('')
        setReply('')
        if (textForQr,qtForPdf) {
            setLoad(true)
            try {
                const response = await QrService.genQr(textForQr,qtForPdf)
                console.log(response)
                setReply(response.data.message)
                setQrArray(response.data.url)
            } catch (e) {
                console.log(e);
                if (e.response.data.message) {
                    setReply(e.response.data.message)
                } else {setReply(e.message)}
            } finally {
                setLoad(false)
                setTextForQr('')
                setQtForPdf('')
            }
        }else {
            setReply('Введите данные')
        }
    }

    async function getQr(){

    }


    return (
        <div style={{marginTop: 50}}>
            <div className="box">
            
            <center><h1 className="fs-4">Генератор QR-Кодов</h1></center>
            <div className="hstack gap-3" style={{marginTop: 25}}>
                    
                    <input
                        disabled={load}
                        className="form-control me-auto"
                        type="text"
                        placeholder='Номер заказа'
                        aria-label="Номер заказа"
                        onChange={e => setTextForQr(e.target.value)}
                        value={textForQr}
                    />

                    <input
                        disabled={load}
                        className="form-control me-auto"
                        type="text"
                        placeholder='Кол-во позиций'
                        aria-label="Кол-во позиций"
                        onChange={e => setQtForPdf(e.target.value)}
                        value={qtForPdf}
                    />

                    <button
                        disabled={load}
                        type="button"
                        className="btn btn-secondary"
                        onClick={QrGen}>
                            Сгенерировать
                    </button>
            </div>
            <center><div style={{marginTop: 25}}>{load ? <span className="loader"></span> : <p className="fs-1">{reply}</p>}</div></center>
            
            <center><div style={{marginTop: 25}}>{qrArray ? <a
                className="button is-primary is-outlined"
                href={qrArray}
                target="_blank" rel="noreferrer">
                Скачать
            </a> : ''}</div></center>



             

            </div>
        </div> 
    )

}
