import React, {useState, useEffect} from 'react';
import UserService from "../../service/UserService";

export const Wbsupply = () => {

    
    useEffect( ()=>{
        mapSupply()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [load, setLoad] = useState(false)
    const [code, setCode] = useState('')
    const [reply, setReply] = useState('')
    //const [settings, setSettings] = useState('')

    const handlerScrollUp = () => {
        window.scrollTo({
            top: 0,
            left: 0,
        })
    }

    ////
    function Scan(){
        setLoad(true)
        setReply('')
        const codeReplaceSupply = code.replace(/[^a-zа-яё-]/gi, '')
        if(codeReplaceSupply === 'WB-GI-' || codeReplaceSupply === 'WB-GI-ALL'){
            return QrSupplyAPI()
        }

        setReply('QR-Code не опознан')
        Final()
    }

    ////
    async function QrSupplyAPI(){
        setinfoSup('')
        setReply(`Делаю запрос поставки ${code}`)
        try {
            const response = await UserService.fetchScanWbSupply(code)
            console.log(response)
            if(response.data.url){
                setReply(() => {return(
                    <div>
                    {response.data.message}
                    <br></br>
                    <a href={response.data.url} target="_blank" rel="noreferrer">Скачать ленту заказов</a>
                    </div>
                    )})
            }else{
                setReply(response.data.message)
            }
            
            setTimeout(() => {
                mapSupply()
            }, 500)

        } catch (e) {
            console.log(e);
            setReply(e.message)
        } finally {Final()} 
    }

    ////
    const [mapSup, setSup] = useState('')
    async function mapSupply(){
        try {
            const response = await UserService.fetchMapSupply()
            //console.log(response.data.message)
            let toMap = response.data.message
            setSup(() => {return(
                    <div>
                        <table className="table">
                            <thead>
                                <tr>
                                <th>Номер поставки</th>
                                <th>Дата внесения</th>
                                <th>Заказов</th>
                                <th>Склад</th>
                                <th>WB</th>
                                <th>Подробнее</th>
                                <th>Этикетки</th>
                                </tr>
                            </thead>
                            <tbody>
                            {toMap.map(toMap =>
                                <tr key={toMap.id}>
                                <th>{toMap.WbSupply}</th>
                                <td>{toMap.DateScan}</td>
                                <td>{toMap.qtOrders}</td>
                                <td>{toMap.qtOrdersScanPackage}</td>
                                <td>{toMap.qtOrdersWbStatus}</td>
                                <td>
                                    <div className="buttons are-small">
                                        <button
                                            className="button is-info is-outlined"
                                            onClick={() => {infoSupply(toMap.WbSupply)}}>
                                            Подронее
                                        </button>
                                    </div>
                                </td>
                                <th>
                                    <div className="buttons are-small">{
                                        toMap.Stickers ?
                                        <a
                                            className="button is-primary is-outlined"
                                            href={'https://api.fo-den.ru/wb/'+toMap.WbSupply+'.pdf'}
                                            target="_blank" rel="noreferrer">
                                                Скачать
                                        </a> 

                                        :<button 
                                            onClick={async () => {
                                                setCode(toMap.WbSupply)
                                                setLoad(true)
                                                setReply('')
                                                try {
                                                    const response = await UserService.fetchScanWbSupply(toMap.WbSupply)
                                                    console.log(response)
                                                    mapSupply()
                                                    if(response.data.url){
                                                        setReply(() => {return(
                                                            <div>
                                                            {response.data.message}
                                                            <br></br>
                                                            <a href={response.data.url} target="_blank" rel="noreferrer">Скачать ленту заказов</a>
                                                            </div>
                                                            )})
                                                    }else{
                                                        setReply(response.data.message)
                                                    }
                                                    
                                                } catch (e) {
                                                    console.log(e);
                                                    setReply(e.message)
                                                } finally {Final()}
                                            }}
                                            className="button is-info is-outlined">
                                                Сгенерировать
                                        </button> 
                                    }
                                 
                                 </div></th>                     
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )
            })
        } catch (e) {
            console.log(e);
        }
    }

    ////
    const [infoSup, setinfoSup] = useState('')
    async function infoSupply(supply){
        try {
            window.scrollBy(0, 0)
            const response = await UserService.fetchMapSupplyInfo(supply)
            //console.log(response.data.message);
            let supInfo = response.data.message


            setReply('')
            setinfoSup(() => {return(
                <div>
                
                        <div className="field has-addons">
                            <div className="buttons are-small">
                                <button className="button is-danger is-outlined"
                                    style={{marginLeft: 10}}
                                    onClick={() => {setinfoSup('')}}>
                                    <span>Закрыть</span>
                                    <span className="icon is-small">
                                    <i className="fas fa-times"></i>
                                    </span>
                                </button>
                            </div>
                            <h1 style={{marginLeft: 10}}>Информация по поставке: {supply}</h1>
                        </div>
                   
                    <div style={{marginTop: 10}}>
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                <th>ID Заказа</th>
                                <th>BarCode Заказа</th>
                                <th>Дата заказа</th>
                                <th>Продукт</th>
                                <th>WB</th>
                                <th>Склад</th>
                                </tr>
                            </thead>
                            <tbody>
                                {supInfo.map(supInfo =>
                                    <tr key={supInfo.WbId}>
                                    <th>{supInfo.WbId}</th>
                                    <td>{supInfo.WbBarCode}</td>
                                    <td>{supInfo.WbDateOrder}</td>
                                    <td>{supInfo.KNameProduct}</td>
                                    <td>{supInfo.WbStatus ? '✅': '❌'}</td>
                                    <td>{supInfo.ScanQtPackage}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                </div>
            )})
            handlerScrollUp()
        } catch (e) {
            console.log(e);
        }


    }

    ////
    function alertNewSupply(){
        const result = window.confirm('Вы уверены что хотите создать и отправить новую поставку?')
        if (result) {
            newSupply()
        }
    }

    async function newSupply(){
        setinfoSup('')
        setLoad(true)
        setReply(`Формирую новую поставку`)
        try {
            const response = await UserService.fetchNewSupply()
            console.log(response)
            if(response.data.url){
                setReply(() => {return(
                    <div>
                    {response.data.message}
                    <br></br>
                    <a href={response.data.url} target="_blank" rel="noreferrer">Скачать ленту заказов</a>
                    </div>
                    )})
            }else{
                setReply(response.data.message)
            }
            
            setTimeout(() => {
                mapSupply()
            }, 500)

        } catch (e) {
            console.log(e);
            setReply(e.message)
        } finally {Final()}

    }

    ////
    function Final(){
        setLoad(false)
        setCode('')
    }
    
    // async function Settings(){
    //     try {
    //         const response = await UserService.fetchEmailSupply()
    //         console.log(response) 
    //         let emails = response.data
    //         setSettings(() => {
    //             return(
    //                 <div className="notification" style={{marginTop: 10}}>
    //                     <table className="table is-fullwidth">
    //                             <thead>
    //                                 <tr>
    //                                 <th>EMail для отправки поставок</th>
    //                                 <th>Функция</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
                                    
    //                                 {emails.map(emails =>
    //                                     <tr key={emails._id}>
    //                                     <th>{emails.email}</th>
    //                                     <td><div className="buttons are-small"><button className="button is-danger" disabled={emails.del}>Удалить</button></div></td>
    //                                     </tr>
    //                                 )}
    
    //                                 <tr>
    //                                 <th><input className="form-control me-auto" type="text" disabled={true}/></th>
    //                                 <td><button className="button is-success" disabled={true}>Добавить</button></td>
    //                                 </tr>
    
    //                             </tbody>
    //                         </table>
    //                 </div> 
    //             )
    //         })

    //     } catch (e) {
    //         console.log(e);
    //     }

        
    // }
    //{settings ? <button style={{float:'right'}} className="button is-white" onClick={() => setSettings('')}>🚫</button> : <button style={{float:'right'}} className="button is-white" onClick={Settings}>⚙️</button>}
    //<center>{settings}</center>
    return(

        <div style={{marginTop: 50}}>
        

            <div className="box">

                <center><h1 className="fs-3">Поставки WB</h1></center>
                    
                    <div className="hstack gap-3" style={{marginTop: 25}}>
                    <input
                        disabled={load}
                        className="form-control me-auto"
                        type="text"
                        placeholder='Поставка WB - "WB-GI-12345678"'
                        aria-label="Add your item here..."
                        onChange={e => setCode(e.target.value)}
                        value={code}
                    />

                    <button
                        disabled={load}
                        type="button"
                        className="btn btn-secondary"
                        onClick={Scan}>
                            Добавить
                    </button>
                    
                    <div className="vr"></div>

                    <button type="button"
                        disabled={load}
                        className="btn btn-outline-danger"
                        onClick={alertNewSupply}>
                            Автопоставка
                    </button>
                    </div>

                    <div>{reply ?
                        <center>
                            <div className="notification" style={{marginTop: 10}}>
                                <button className="delete" onClick={() => setReply('')}></button>
                                {reply}
                            </div> 
                        </center>
                    : ''}</div>
                    
                <div style={{marginTop: 50}}>{infoSup}</div>

                <div style={{marginTop: 50}}>{load ? <center><span className="loader"></span></center> : <div>{mapSup}</div>}</div>

            </div>

        </div>
    )
}

/**            <div style={{marginTop: 50}} className='box'>
                <h1 style={{marginBottom:10}} >Функционал для отладки</h1>
                <button
                    onClick={mapSupply}>
                    Refresh
                </button>
                
                <button
                    onClick={handlerScrollUp}
                    style={{marginLeft: 5}}>
                    ScrollUp
                </button>
            </div> */