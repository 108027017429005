import React, {} from 'react';
import {Redirect} from 'react-router-dom'

export const QrHome = () => {

    return (
        <div style={{marginTop: 50}}>
            <Redirect to="/qr/gen" />
        </div>
    )

}
