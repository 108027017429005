import $api from "../http";

export default class CertService {

    static async checkCert(code){
        return $api.post('/cert/check', {code})
    }


}

