import AuthService from "../service/AuthService"
import {makeAutoObservable} from "mobx";
import axios from "axios";
import { API_URL } from "../http";

export default class Store {
    user = {}
    isAuth = false
    isLoading = false

    constructor(){
        makeAutoObservable(this)
    }

    setAuth(bool){
        this.isAuth = bool
    }

    setUser(user){
        this.user = user
    }

    setAccess(access){
        this.access = access
    }

    setLoading(bool){
        this.isLoading = bool
    }



    async login(email, password){
        try{
            const response = await AuthService.login(email,password)
            console.log(response);
            localStorage.setItem('token', response.data.userData.accessToken)
            this.setAuth(true)
            this.setUser(response.data.userData.user)
            this.setAccess(response.data.access.accessPage)
        }catch(e){
            console.log(e.response?.data?.message)
            return e.response?.data?.message
        }
    }

    async registration(email, password, code){
        try{
            const response = await AuthService.registration(email,password,code)
            console.log(response);
            localStorage.setItem('token', response.data.accessToken)
            this.setAuth(true)
            this.setUser(response.data.user)
        }catch(e){
            console.log(e.response?.data?.message)
            return e.response?.data?.message
        }
    }

    async logout(){
        try{
            const response = await AuthService.logout()
            console.log(response);
            localStorage.removeItem('token')
            this.setAuth(false)
            this.setUser('')
        }catch(e){
            console.log(e.response?.data?.message)
        }
    }


    async checkAuth(){
        this.setLoading(true)
        try {
            const response = await axios.get(`${API_URL}/auth/refresh`, {withCredentials: true})
            //console.log(response);
            localStorage.setItem('token', response.data.userData.accessToken)
            this.setAuth(true)
            this.setUser(response.data.userData.user)
            this.setAccess(response.data.access.accessPage)
        }catch(e){
            try{
                const response = await AuthService.logout()
                console.log(response);
                localStorage.removeItem('token')
                this.setAuth(false)
                this.setUser('')
            }catch(e){
                console.log(e.response?.data?.message)
            }
            console.log(e.response?.data?.message)
        } finally {
            this.setLoading(false)
        }
    }
    

}