import React, {useState, useEffect} from 'react';
import UserService from "../../service/UserService";

export const WbOtchet = () => {
    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')
    const [code, setCode] = useState('')
    const [datamin, setDatamin] = useState('')
    const [datamax, setDatamax] = useState('')

    const now = new Date();
    const nowDayISO = now.toISOString()
    let nowDay = ''
    for (let i = 0; i < nowDayISO.length; i++) {
        if (nowDayISO[i] === 'T') {
            break
        }
        nowDay = nowDay+nowDayISO[i]
    }
    
    useEffect( ()=>{
        setDatamin(nowDay)
        setDatamax(nowDay)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    function test(){
        console.log(datamin);
        console.log(datamax);
    }

    return(

    <div style={{marginTop: 50}}>
        <div><label>В Разработке</label></div>

        <label>Период отчета</label>
        
        <input
            type="date"
            id="bday"
            name="bday"
            value={datamin}
            onChange={e => setDatamin(e.target.value)}
            style={{marginLeft: 10}}
        />

        <label style={{marginLeft: 10}}>-</label>
            
        <input
            type="date"
            id="bday"
            name="bday"
            value={datamax}
            onChange={e => setDatamax(e.target.value)}
            style={{marginLeft: 10}}
        /> 

        <button
            type="button"
            onClick={test}
            style={{marginLeft: 10}}
            className="btn btn-outline-secondary btn-sm">
                Сформировать
        </button>

        </div>

    )
}