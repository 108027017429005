import React, {useContext, useState} from 'react';
import {Context} from "../../index";

export const AuthPage = () => {
        const [email, setEmail] = useState('')
        const [password, setPassword] = useState('')
        const [code, setCode] = useState('')
        const {store} = useContext(Context);

        const [otvet, setOtvet] = useState('')

        async function login(){
            setOtvet('')
            const response = await store.login(email, password)
            setOtvet(response)
        }

        async function registration(){
            if(code){
                setOtvet('')
                const response = await store.registration(email, password, code)
                setOtvet(response)
            }else{setOtvet('Укажите код регистрации')}
        }

        return (
            <div className="columns">
                <div className="column"></div>

                <div className="column" style={{marginTop: 50}}>
                    <h1 className="title">KApp</h1>
                    <div className="box">
                        <div className="field">
                            <label className="label">Email</label>
                            <div className="control">
                            <input
                                className="input"
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                type="text"
                                placeholder='e.g. alex@example.com'
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Пароль</label>
                            <div className="control">
                            <input
                                className="input"
                                onChange={e => setPassword(e.target.value)}
                                value={password}
                                type="password"
                                placeholder="********"
                            />
                            </div>
                        </div>
                        

                    
                        <div className="field has-addons">
                        <button
                            className="button is-primary"
                            style={{marginRight: 10}}
                            onClick={login}>
                            Войти
                        </button>

                        
                        <button
                            className="button is-primary"
                            style={{marginRight: 10}}
                            onClick={registration}>
                            Регистрация
                        </button>
                        
                        <input
                            className="input"
                            onChange={e => setCode(e.target.value)}
                            value={code}
                            type="text"
                            placeholder="Код рег."
                        />
                        
                        
                        </div>

                        <div className="field">

                            <h1 style={{marginTop: 10}}>{otvet}</h1>
                            
                        </div>
                        
                    </div>
                </div>
                <div className="column"></div>
            </div>
        )
};



