import React, {} from 'react';
import {Redirect} from 'react-router-dom'

export const MarkingAdmin = () => {

    return (
            <div style={{marginTop: 50}}>
                <Redirect to="/markingadmin/fix" />
            </div>
    )

}
