import React, {useState, useEffect} from 'react';
import RetailService from "../../service/RetailService";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


export const OzonOneC = () => {
    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')
    const [fileObject, setFileObject] = useState('');
    const [data, setData] = useState([]);

    
    useEffect(() => {
        if (fileObject) {
          ExcelRenderer(fileObject, (err, resp) => {
            if (err) {
              console.log(err);
            } else {
              const modifyData = resp.rows?.slice(1)?.map((itm, index) => ({
                Sku: itm[10] || "",
                Price: itm[11] || "",
                Qt: itm[13] || "",
              }));
              setData(modifyData);
            }
          });
        }
      }, [fileObject]);

    async function postServer(){
        setLoad(true)
        setReply('')
        if (data.length!==0) {
            console.log(data);
            try {
                const response = await RetailService.importFullOtchetRetail(data)
                console.log(response);
                setReply(response.data.message)

            } catch (e) {
                console.log(e);
                setReply(e.response?.data?.message + ' ' + e.response?.status)
            }
        } else {
            setReply('Внесите информацию во все пункты!')
        }
        setLoad(false)
    }



    return(

    <div className='box' style={{marginTop: 50}}>
        <div style={{marginTop: 10}}>
        <center><div style={{marginTop: 15}}> {load ? <span className="loader"/> : <>{reply}</>}</div></center>
        
        <center style={{marginTop: 25, marginBottom:25}}>
            <div class="input-group">
                <input
                    type="file"
                    className="form-control"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    onChange={(e) => {
                        setFileObject(e.target.files[0]);
                    }}
                    accept='.csv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                />

                <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="inputGroupFileAddon04"
                    onClick={postServer}>
                    Получить артикул KAORI 
                </button>
            </div>
        </center>

        <ReactHTMLTableToExcel
            className="btn btn-outline-secondary btn-sm"
            table="OZON-table-to-xls"
            filename="OZON-table-to-xls"
            sheet="OZON-table-to-xls"
        buttonText="Скачать в XLS"/>

        <table id="OZON-table-to-xls" className="table is-fullwidth" style={{marginTop: 10}}>
            <thead>
            <tr>
                <th>Артикул KAORI</th>
                <th>Артикул OZON</th>
                <th>Цена OZON</th>
                <th>КОЛ-ВО</th>
            </tr>
            </thead>
            {/* ))} */}
            {data?.map((item) => (
            <tbody>
                <tr>
                <td>{}</td>
                <td>{item?.Sku}</td>
                <td>{item?.Price}</td>
                <td>{item?.Qt}</td>
                </tr>
            </tbody>
            ))}
        </table>
        </div>
    </div>

    )
}