import React, {useState} from 'react';
import {Redirect} from 'react-router-dom'

import CertService from "../../service/CertService";


export const CertHome = () => {
    const [load, setLoad] = useState(false)
    const [reply, setReply] = useState('')
    const [code, setCode] = useState('')
    const [doc, setDoc] = useState('')

    function PressKey(event){
        if (!load && code && event.key === 'Enter'){
            Check()
        }
    }

    async function Otmena(){
        setLoad(false)
        setReply('')
        setCode('')
        setDoc('')
    }

    async function Activate(){
        if (doc && code) {
            try {
                const response = await CertService.actCert(code, doc)
            } catch (e) {
                setCode('')
                setReply(e.response?.data?.message + ' ' + e.response?.status)
                console.log(e.response)
            }
        }
    }

    async function Check(){
        if (!load && code){
            setLoad(true)
            setReply('')

            try {
                const response = await CertService.checkCert(code)

                if (response.data.message === 'Сертификат не найден') {
                    setReply(() => {return(<>Сертификат не найден!</>)})
                    setCode('')
                }

                if (response.data.message === 'Сертификат готов к продаже') {
                    setReply(() => {return(
                        <div> 
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th>Номер</th>
                                    <th>Штрихкод</th>
                                    <th>Номинал</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <th>{response.data.info[0].number}</th>
                                    <td>{response.data.info[0].code}</td>
                                    <td>{response.data.info[0].price}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="hstack gap-3" style={{marginTop: 25}}>
                                <input
                                    disabled='true'
                                    className="form-control me-auto"
                                    type="text"
                                    placeholder='Название магазина'
                                    aria-label="Add your item here..."
                                    value= {'Ваш магазин: ' + response.data.info[0].shop}
                                />
                                <input
                                    disabled={load}
                                    className="form-control me-auto"
                                    type="text"
                                    placeholder='Номер чека'
                                    aria-label="Add your item here..."
                                    onChange={e => setDoc(e.target.value)}
                                    value={doc}
                                />

                                <button
                                    disabled={load}
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={Activate}>
                                        Активировать
                                </button>

                                <button
                                    disabled={load}
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={Otmena}>
                                        Отмена
                                </button>
                        </div>
                        </div>
                    )})
                }

                if (response.data.message === 'Сертификат продан') {
                    setReply(() => {return(<>
                        
                    </>)})
                }

                if (response.data.message === 'Сертификат использован') {
                    setReply(() => {return(<>Сертификат уже был использован</>)})
                    setCode('')
                }
      
            } catch (e) {
                setCode('')
                setReply(e.response?.data?.message + ' ' + e.response?.status)
                console.log(e.response)
            }

            setLoad(false)
            
        }   
    }


    return (
        <div>
            <div className='box' style={{marginTop: 50}}>
                <center><h1 className="fs-3">Проверка сертификатов</h1></center>
                <div className="input-group input-group-lg" style={{marginTop: 25}}>
                
                <input type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    onKeyUp={PressKey}
                    autoFocus={true}/>
                    <button type="button" className="btn btn-outline-secondary" onClick={Check}>Проверить</button>
                </div>

                <center><div style={{marginTop: 25}}> {load ? <span className="loader"/> : <>{reply}</>} </div></center>
            </div>
        </div>
    )

}
