import React, {useState} from 'react';
import MarkingService from "../../service/MarkingService";

import rab from "../../img/2GU.gif";


export const MarkingScanMass = () => {
   
    const [counterparty_load, setCounterpartyLoad] = useState(false)
    const [order, setСounterparty] = useState('')
    
    const [code, setCode] = useState('')

    const [reply, setReply] = useState('')

    async function SetСounter(){
        setReply('')
        if (!order) {
            setReply('Укажите контрагента')
        }
        if (order) {
            setCounterpartyLoad(true)
        }
    }

    async function SetСounterPreset(preset){
        setReply('')
        if (preset) {
            setСounterparty(preset)
            setCounterpartyLoad(true)
        }
    }

    async function ClearСounter(){
        setReply('')
        setСounterparty('')
        setCounterpartyLoad(false)
    }


    function PressKey(event){
        if (counterparty_load && code && event.key === 'Enter'){
            Check()
        }
    }
    
    async function Check(){
        //setLoad(true) //убрал слетает автотаргет, неудобненько :(
        setReply('')
        try {

            setReply(`Проверяю ${code}, для ${order}`)
            const response = await MarkingService.fetchCheckMarking(code)
            console.log(response.data)
            
            let status
            if (response.data.Status) {
                if (response.data.Status[0] === 'н' && response.data.Status[1] === 'а' && response.data.Status[2] === 'б' && response.data.Status[3] === 'о' && response.data.Status[4] === 'р') {
                    status = response.data.Status
                }
            }

            if (response.data.Counterparty) {
                setReply(()=>{
                    return(
                        <div> 
                        <h1 className="fs-4">❌ У данного кода уже был указан контрагент ранее ❌</h1>
                        <table className="table">
                            <thead>
                                <tr>
                                <th>Номер этикетки</th>
                                <th>Продукт</th>
                                <th>Владелец</th>
                                <th>Контрагент</th>
                                <>{status ? <th>Статус</th> : <></>}</>
                                <th>Возврат</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <th>{`${response.data.Block} - ${response.data.Number}`}</th>
                                <td>{response.data.Product}</td>
                                <td>{response.data.Owner}</td>
                                <td>{response.data.Counterparty ? response.data.Counterparty : 'Нету' }</td>
                                <>{status ? <td>{status}</td> : <></>}</>
                                <td>{response.data.Return ? 'Да' : 'Нет' }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    )
                })
            }

            if (!response.data.Counterparty) {
                
                try {
                    const response = await MarkingService.fetchConfirmMarking({code, order})
                    console.log(response.data)
                    try {
                        const response = await MarkingService.fetchCheckMarking(code)
                        console.log(response.data)
                        setReply(()=>{
                            return(
                                <div> 
                                <h1 className="fs-4">✅ Успешно установленн новый контрагент! ✅</h1>
                                <table className="table">
                                    <thead>
                                        <tr>
                                        <th>Номер этикетки</th>
                                        <th>Продукт</th>
                                        <th>Владелец</th>
                                        <th>Контрагент</th>
                                        <>{status ? <th>Статус</th> : <></>}</>
                                        <th>Возврат</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <th>{`${response.data.Block} - ${response.data.Number}`}</th>
                                        <td>{response.data.Product}</td>
                                        <td>{response.data.Owner}</td>
                                        <td>{response.data.Counterparty ? response.data.Counterparty : 'Нету' }</td>
                                        <>{status ? <td>{status}</td> : <></>}</>
                                        <td>{response.data.Return ? 'Да' : 'Нет' }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            )
                        })
                    } catch (e) {
                        console.log(e);
                        setReply(e.response?.data?.message + ' ' + e.response?.status)
                    }
                    
                } catch (e) {
                    console.log(e);
                    setReply(e.response?.data?.message + ' ' + e.response?.status)
                }

            }

        
        } catch (e) {
            setReply(e.response?.data?.message + ' ' + e.response?.status)
            console.log(e)
        }
        

        //setLoad(false)
        setCode('')
    }

    return(
        <div>
            <div className='box' style={{marginTop: 50}}>
            
            <center><h1 className="fs-3">Массовое внесение ЧЗ по контрагенту</h1></center>
            
            <div style={{marginTop: 25}}>
            {/* <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('СкладАкадем')}} disabled={counterparty_load}>СкладАкадем</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('Академическая')}} disabled={counterparty_load} style={{marginLeft: 15}}>Академическая</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('Тульская')}} disabled={counterparty_load} style={{marginLeft: 15}}>Тульская</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('Депо')}} disabled={counterparty_load} style={{marginLeft: 15}}>Депо</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('Омега')}} disabled={counterparty_load} style={{marginLeft: 15}}>Омега</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('СПБ')}} disabled={counterparty_load} style={{marginLeft: 15}}>СПБ</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('WB')}} disabled={counterparty_load} style={{marginLeft: 15}}>WB</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('Выставка')}} disabled={counterparty_load} style={{marginLeft: 15}}>Выставка</button> */}

            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('СкладАкадем')}} disabled={counterparty_load}>СкладАкадем</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('OZON')}} disabled={counterparty_load} style={{marginLeft: 15}}>OZON</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('СПБ')}} disabled={counterparty_load} style={{marginLeft: 15}}>СПБ</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('WB')}} disabled={counterparty_load} style={{marginLeft: 15}}>WB</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('Выставка')}} disabled={counterparty_load} style={{marginLeft: 15}}>Выставка</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('Афимолл')}} disabled={counterparty_load} style={{marginLeft: 15}}>Афимолл</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{SetСounterPreset('Европейский')}} disabled={counterparty_load} style={{marginLeft: 15}}>Европейский</button>

            </div>

            <div className="input-group input-group-lg" style={{marginTop: 25}}>
                <input type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    value={order}
                    onChange={e => setСounterparty(e.target.value)}
                disabled={counterparty_load}/>

                {counterparty_load ? 
                    <button type="button" className="btn btn-outline-secondary" onClick={ClearСounter}>Заменить контрагента</button> :
                    <button type="button" className="btn btn-outline-secondary" onClick={SetСounter}>Указать контрагента</button>
                }
            </div>

                {counterparty_load ? 
                    <div> 
                     <center style={{marginTop: 25}}>
                        <h1 className="fs-5">СКАНИРУЙ</h1>
                        <h1 className="fs-5">\/  \/  \/  \/  \/</h1>
                    </center>
                    <input type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-lg"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        onKeyUp={PressKey}
                        style={{marginTop: 25}}
                        autoFocus={true}
                    /></div>
                    : ""
                }
            
            <center>
                <div style={{marginTop: 25}}> <>{reply}</> </div>
            </center>
            


            <center style={{marginTop: 25}}>
                <img src={rab} alt='123'></img>
            </center>

            </div>
        </div>
    )
}