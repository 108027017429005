import { useContext } from "react";
import { Context } from "../";


export const Home = () => {
    const {store} = useContext(Context)

    return (

         
            <div style={{marginTop: 50}}>
                <div className="box" style={{marginRight: 50,marginLeft: 50}}>
                    <center>

                        <h1>Добро пожаловать в KApp</h1>
                        <h1 style={{marginTop: 20}}>Выберите нужный раздел вверху страницы </h1>
                         
                        <div style={{marginTop: 50}}>

                        
                        <button
                            className="button is-danger"
                            onClick={()=>store.logout()}>
                            Выход
                        </button>

                        </div>
                        
                    </center>
                </div>
            </div>

    )

}