import React, {useState, useEffect} from 'react';
import ParsService from "../../service/ParsService";

export const ParsCenAdd = () => {
    
    useEffect( ()=>{
        getStore()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [load, setLoad] = useState(false)
    
    const [store, setStore] = useState('')
    const [urlStore, setUrlStore] = useState('')
    const [paramStore, setParamStore] = useState('')

    const [storeItem, setStoreItem] = useState('')
    const [urlItem, setUrlItem] = useState('')
    const [nameItem, setNameItem] = useState('')
    const [firstPriceItem, setFirstPriceItem] = useState('')

    const [mapStore, setMapStore] = useState('')
    const [mapItem, setMapItem] = useState('')


    const [reply, setReply] = useState('')

    async function AddStore(){
        setReply('')
        setLoad(true)
        if (!store || !urlStore || !paramStore) {
            setLoad(false)
            setReply('Один из параметров был не указан')
            return
        }

        try {
            const response = await ParsService.addStorePars(store, urlStore, paramStore)
            console.log(response)
            setReply(response.data.message)
        } catch (e) {
            console.log(e);
            if (e.response.data.message) {
                setReply(e.response.data.message)
            } else {setReply(e.message)}
        } finally {
            setLoad(false)
            getStore()
        }

    }

    async function AddItem(){
        setReply('')
        setLoad(true)
        if (!storeItem || !urlItem || !nameItem || !firstPriceItem) {
            setLoad(false)
            setReply('Один из параметров был не указан')
            return
        }

        try {
            const response = await ParsService.addItemPars(storeItem, urlItem, nameItem, firstPriceItem)
            console.log(response)
            setReply(response.data.message)
        } catch (e) {
            console.log(e);
            if (e.response.data.message) {
                setReply(e.response.data.message)
            } else {setReply(e.message)}
        } finally {
            setLoad(false)
            getStore()
        }
    }
    
    async function DelStore(id){
        setReply('')
        setLoad(true)
        if (!id) {
            setLoad(false)
            setReply('Один из параметров был не указан')
            return
        }
        console.log(id);
        try {
            const response = await ParsService.delStoresPars(id)
            console.log(response)
            setReply(response.data.message)
        } catch (e) {
            console.log(e);
            if (e.response.data.message) {
                setReply(e.response.data.message)
            } else {setReply(e.message)}
        } finally {
            setLoad(false)
            getStore()
        }

    }

    async function GetItems(name){
        setReply('')
        setMapItem('')
        setLoad(true)
        if (!name) {
            setLoad(false)
            setReply('Один из параметров был не указан')
            return
        }
        console.log(name);
        try {
            const response = await ParsService.getItemsPars(name)
            console.log(response)

            setMapItem(() => {return(
                <table className="table">
                <thead>
                    <tr>
                    <th>ID</th>
                    <th>Название</th>
                    <th>Магазин</th>
                    </tr>
                </thead>
                <tbody>
                {response.data.message.map(toMap =>
                    <tr key={toMap.id}>
                    <th>{toMap.id}</th>
                    <th>{toMap.name}</th>
                    <td>{toMap.store_name}</td>
                    {/* <td><button className="btn btn-outline-danger btn-sm" onClick={()=>{DelStore(toMap.id)}}>Удалить товар</button></td>                         */}
                    </tr>
                )}
                </tbody>
            </table>
            )})

        } catch (e) {
            console.log(e);
            if (e.response.data.message) {
                setReply(e.response.data.message)
            } else {setReply(e.message)}
        } finally {
            setLoad(false)
            getStore()
        }

    }

    async function getStore(){
        try {
            const response = await ParsService.getStoresPars()
            console.log(response)

            setMapStore(() => {return(
                <table className="table">
                <thead>
                    <tr>
                    <th>ID</th>
                    <th>Название</th>
                    <th>Параметр</th>
                    <th>URL</th>
                    <th>Подробнее</th>
                    <th>Удаление</th>
                    </tr>
                </thead>
                <tbody>
                {response.data.message.map(toMap =>
                    <tr key={toMap.id}>
                    <th>{toMap.id}</th>
                    <th>{toMap.name}</th>
                    <td>{toMap.param}</td>
                    <td>{toMap.url}</td>
                    <td><button className="btn btn-outline-info btn-sm" onClick={()=>{GetItems(toMap.name)}}>Подробнее</button></td>
                    <td><button className="btn btn-outline-danger btn-sm" onClick={()=>{DelStore(toMap.id)}}>Удалить магазин</button></td>              
                    </tr>
                )}
                </tbody>
            </table>

            )})
        } catch (e) {
            console.log(e);
        }
    }


    return (
        <div style={{marginTop: 50}}>
            <div className="box">

            <center><h1 className="fs-4">Добавление магазина</h1></center>
            <div className="hstack gap-3" style={{marginTop: 25}}>
                    <input
                        disabled={load}
                        className="form-control me-auto"
                        type="text"
                        placeholder='Название магазина'
                        aria-label="Add your item here..."
                        onChange={e => setStore(e.target.value)}
                        value={store}
                    />
                    <input
                        disabled={load}
                        className="form-control me-auto"
                        type="text"
                        placeholder='Ссылка на магазин'
                        aria-label="Add your item here..."
                        onChange={e => setUrlStore(e.target.value)}
                        value={urlStore}
                    />
                    <input
                        disabled={load}
                        className="form-control me-auto"
                        type="text"
                        placeholder='Параметр для парсинга'
                        aria-label="Add your item here..."
                        onChange={e => setParamStore(e.target.value)}
                        value={paramStore}
                    />

                    <button
                        disabled={load}
                        type="button"
                        className="btn btn-secondary"
                        onClick={AddStore}>
                            Добавить
                    </button>
            </div>

            <center style={{marginTop: 25}}><h1 className="fs-4">Добавление товара</h1></center>
            <div className="hstack gap-3" style={{marginTop: 25}}>
                    <input
                        disabled={load}
                        className="form-control me-auto"
                        type="text"
                        placeholder='ID Магазина'
                        aria-label="Add your item here..."
                        onChange={e => setStoreItem(e.target.value)}
                        value={storeItem}
                    />
                    <input
                        disabled={load}
                        className="form-control me-auto"
                        type="text"
                        placeholder='Ссылка на товар'
                        aria-label="Add your item here..."
                        onChange={e => setUrlItem(e.target.value)}
                        value={urlItem}
                    />
                    <input
                        disabled={load}
                        className="form-control me-auto"
                        type="text"
                        placeholder='Название товара'
                        aria-label="Add your item here..."
                        onChange={e => setNameItem(e.target.value)}
                        value={nameItem}
                    />
                    <input
                        disabled={load}
                        className="form-control me-auto"
                        type="text"
                        placeholder='Начальная цена'
                        aria-label="Add your item here..."
                        onChange={e => setFirstPriceItem(e.target.value)}
                        value={firstPriceItem}
                    />

                    <button
                        disabled={load}
                        type="button"
                        className="btn btn-secondary"
                        onClick={AddItem}>
                            Добавить
                    </button>
            </div>

            <div style={{marginTop: 50}}>{load ? <center><span className="loader"></span></center> : <center><h1 className="fs-4" style={{marginTop: 25}}>{reply}</h1></center>}</div>
            {mapItem}
            {mapStore}
            </div>
        </div>
    )

}
