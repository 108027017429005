import $api from "../http";

export default class ParsService {
    
    
    static addStorePars(store,urlStore,paramStore){
        return $api.post('/pars/addStore', {store,urlStore,paramStore})
    }

    static getStoresPars(){
        return $api.get('/pars/getStores')
    }

    static delStoresPars(id){
        return $api.post('/pars/delStores', {id})
    }

    static addItemPars(storeItem, urlItem, nameItem, firstPriceItem){
        return $api.post('/pars/addItem', {storeItem,urlItem,nameItem,firstPriceItem})
    }

    static getItemsPars(name){
        return $api.post('/pars/getItems', {name})
    }
    
}