import React, {} from 'react';
import {Redirect} from 'react-router-dom'

export const ParsCen = () => {

    return (
        <div style={{marginTop: 50}}>
            <Redirect to="/parscen/add" />
        </div>
    )

}
