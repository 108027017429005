import {useContext, useEffect} from "react";
import './styles/index.css'


import {Context} from ".";
import {observer} from  "mobx-react-lite"

import {BrowserRouter as Router} from 'react-router-dom'
import {useRoutes} from './routes'

import Navbar from './components/Navbar'


function App() {

  const {store} = useContext(Context)
  const isAuthenticated = store.isAuth
  const isActivated = store.user.isActivated
  const isLoading = store.isLoading
  const routes = useRoutes({isAuthenticated, isActivated, isLoading})

  useEffect( ()=>{
    if(localStorage.getItem('token')){
      store.checkAuth()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  if (isLoading){
    return (
      <div style={{
        position: 'absolute',
        margin: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100px',
        height: '100px',
      }}> 
        <div className="columns">
          <div className="column">
            <span className="loader"></span>
          </div>
          <div className="column">
            <span>KApp</span>
          <br/>Загрузка...
          </div>
        </div>
      </div>
      
    )
  }

  return (
    
    <Router >
      { isAuthenticated && <Navbar /> }
      <div className="container">
        <div className="row">
          <div className="col">
            
            
          </div>
          <div className="col-30">
          {routes}
          </div>
          <div className="col">
            
            
          </div>
        </div>
      </div>
    </Router>
  )


  //<h1>{store.isAuth ? "Пользователь авторизован" : "АВТОРИЗУЙТЕСЬ"}</h1>


}

export default observer(App);
