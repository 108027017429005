import React, {useState} from 'react';
import MarkingService from "../../service/MarkingService";

export const MarkingScanDate = () => {
    const [load, setLoad] = useState(false)
    const [load1, setLoad1] = useState(false)

    const [action, setAction] = useState(false)
    const [order, setOrder] = useState('')

    const [reply, setReply] = useState('')
    const [reply1, setReply1] = useState('')
    const [code, setCode] = useState('')
    
    const [date, setDate] = useState('')

    function PressKey(event){
        if (!load && !load1 && code && event.key === 'Enter'){
            Check()
        }
    }

    function Otmena(){
        setCode('')
        setOrder('')
        setReply('')
        setReply1('')
        setAction(false)
        setLoad(false)
        setLoad1(false)
    }
    
    async function Check(){
        if (!load && !load1 && code){
            setLoad(true)
            setLoad1(true)
            setReply('')

            try {
                const response = await MarkingService.fetchCheckMarking(code)
                console.log(response.data)

                setReply(()=>{
                    return(
                        <div> 
                        <table className="table">
                            <thead>
                                <tr>
                                <th>Номер этикетки</th>
                                <th>Продукт</th>
                                <th>Владелец</th>
                                <th>Контрагент</th>
                                <th>Статус</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <th>{`${response.data.Block} - ${response.data.Number}`}</th>
                                <td>{response.data.Product}</td>
                                <td>{response.data.Owner}</td>
                                <td>{response.data.Counterparty ? response.data.Counterparty : 'Нету' }</td>   
                                <td>{response.data.Status}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    )
                })

                if (response.data.Counterparty) {
                    // setCode('')
                    // setLoad1(false)
                    setAction(true) // Повторное исправление 
                }

                if (!response.data.Counterparty) {
                    setAction(true)
                }

            } catch (e) {
                console.log(e);
                setLoad1(false)
                setReply(e.response?.data?.message + ' ' + e.response?.status)
                setCode('')
            } finally{
                setLoad(false)
            }       
        }   
    }

    async function ConfirmMarking(){
        if(order){
            console.log(code)
            setLoad(true)
            setReply1('')
            try {
                console.log(code, order, date);
                const response = await MarkingService.fetchConfirmMarkingDate({code, order, date})
                console.log(response)
                setReply('Обновлен')
                
                setCode('')
                setOrder('')
                setReply1('')
                setAction(false)
                setLoad(false)
                setLoad1(false)
            } catch (e) {
                console.log(e);
                setReply(e.response?.data?.message + ' ' + e.response?.status)
                
                setCode('')
                setOrder('')
                setReply1('')
                setAction(false)
                setLoad(false)
                setLoad1(false)
            } 
        } else {
            setReply1('Укажите заказ')
        }
    }

    const otpravkaZakaza = () => {
        return(
            <>
            <div className="input-group input-group-lg" style={{marginTop: 25}}>
            
            <input
            type="date"
            id="bday"
            name="bday"
            value={date}
            onChange={e => setDate(e.target.value)}
            style={{marginLeft: 10}}
            />

            <input type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg"
                value={order}
                onChange={e => setOrder(e.target.value)}
                disabled={load}/>
                <button type="button" className="btn btn-outline-success" onClick={ConfirmMarking} disabled={load}>Отправить</button>
                <button type="button" className="btn btn-outline-danger" onClick={Otmena} disabled={load}>Отмена</button>
            </div>

            {reply1}
            </>
        )
    }

    return(
        <div>
            <div className='box' style={{marginTop: 50}}>
                <center><h1 className="fs-3">Внесение ЧЗ с датой</h1></center>
                <div className="input-group input-group-lg" style={{marginTop: 25}}>
                
                <input type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    onKeyUp={PressKey}
                    autoFocus={true}
                    disabled={load1}/>
                    <button type="button" className="btn btn-outline-secondary" onClick={Check} disabled={load1}>Проверить</button>
                </div>

                <center>
                    <div style={{marginTop: 25}}> {load ? <span className="loader"/> : <>{reply}</>}</div>
                    <div>{action ? otpravkaZakaza() : ""}</div>
                </center>
            </div>
        </div>
    )
}