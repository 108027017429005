import React, {useState} from 'react';
import MarkingService from "../../service/MarkingService";


export const MarkingScan = () => {
    const [load, setLoad] = useState(false)
    const [load1, setLoad1] = useState(false)

    const [action, setAction] = useState(false)
    const [order, setOrder] = useState('')

    const [reply, setReply] = useState('')
    const [reply1, setReply1] = useState('')
    const [code, setCode] = useState('')
    
    const [returnOrder, setReturnOrder] = useState(false)
    const [codeToReturn, setCodeToReturn] = useState('')


    function PressKey(event){
        if (!load && !load1 && code && event.key === 'Enter'){
            Check()
        }
    }

    function Otmena(){
        setCode('')
        setOrder('')
        setReply('')
        setReply1('')
        setAction(false)
        setLoad(false)
        setLoad1(false)
        setReturnOrder(false)
        setCodeToReturn('')
    }

    async function Check(){
        if (!load && !load1 && code){
            setReturnOrder(false)
            setCodeToReturn('')

            setLoad(true)
            setLoad1(true)
            setReply('')

            try {
                const response = await MarkingService.fetchCheckMarking(code)
                console.log(response.data)
                let status
                if (response.data.Status) {
                    if (response.data.Status[0] === 'н' && response.data.Status[1] === 'а' && response.data.Status[2] === 'б' && response.data.Status[3] === 'о' && response.data.Status[4] === 'р') {
                        status = response.data.Status
                    }
                }

                
                setReply(()=>{
                    return(
                        <div> 
                        <table className="table">
                            <thead>
                                <tr>
                                <th>Номер этикетки</th>
                                <th>Продукт</th>
                                <th>Владелец</th>
                                <th>Контрагент</th>
                                <>{status ? <th>Статус</th> : <></>}</>
                                <th>Ликвидирован</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <th>{`${response.data.Block} - ${response.data.Number}`}</th>
                                <td>{response.data.Product}</td>
                                <td>{response.data.Owner}</td>
                                <td>{response.data.Counterparty ? response.data.Counterparty : 'Нету' }</td>
                                <>{status ? <td>{status}</td> : <></>}</>
                                <td>{response.data.Return ? 'Да' : 'Нет' }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    )
                })


                if (response.data.Counterparty && !response.data.Return) {
                    setReturnOrder(true)
                    setCodeToReturn(response.data.Code)
                } // для Ликвидации товара

                if (response.data.Counterparty) {
                    setCode('')
                    setLoad1(false)
                }

                if (!response.data.Counterparty) {
                    setAction(true)
                }

            } catch (e) {
                console.log(e);
                setLoad1(false)
                setReply(e.response?.data?.message + ' ' + e.response?.status)
                setCode('')
            } finally{
                setLoad(false)
            }       
        }   
    }

    async function ConfirmMarking(){
        if(order){
            console.log(code)
            setLoad(true)
            setReply1('')
            try {
                const response = await MarkingService.fetchConfirmMarking({code, order})
                console.log(response)
                setReply('Обновлен')
                
                setCode('')
                setOrder('')
                setReply1('')
                setAction(false)
                setLoad(false)
                setLoad1(false)
            } catch (e) {
                console.log(e);
                setReply(e.response?.data?.message + ' ' + e.response?.status)
                
                setCode('')
                setOrder('')
                setReply1('')
                setAction(false)
                setLoad(false)
                setLoad1(false)
            } 
        } else {
            setReply1('Укажите заказ')
        }
    }

    async function ConfirmMarkingPreset(preset){
        if(preset){
            setOrder(preset)
            setLoad(true)
            setReply1('')
            try {
                const response = await MarkingService.fetchConfirmMarkingPreset({code, preset})
                console.log(response);
                setReply('Обновлен')
                
                setCode('')
                setOrder('')
                setReply1('')
                setAction(false)
                setLoad(false)
                setLoad1(false)
            } catch (e) {
                console.log(e);
                setReply(e.response?.data?.message + ' ' + e.response?.status)
                
                setCode('')
                setOrder('')
                setReply1('')
                setAction(false)
                setLoad(false)
                setLoad1(false)
            } 
        } else {
            setReply1('Укажите заказ')
        }
    }

    const otpravkaZakaza = () => {
        return(
            <div>
            <div className="input-group input-group-lg" style={{marginTop: 25}}>

            <input type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg"
                value={order}
                onChange={e => setOrder(e.target.value)}
                disabled={load}/>

                <button type="button" className="btn btn-outline-success" onClick={ConfirmMarking} disabled={load}>Отправить</button>
                <button type="button" className="btn btn-outline-danger" onClick={Otmena} disabled={load}>Отмена</button>
            </div>

            <div style={{marginTop: 25}}>

            <button type="button" className="btn btn-outline-primary" onClick={()=>{ConfirmMarkingPreset('Академическая')}} disabled={load}>Академическая</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{ConfirmMarkingPreset('Тульская')}} disabled={load} style={{marginLeft: 15}}>Тульская</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{ConfirmMarkingPreset('Депо')}} disabled={load} style={{marginLeft: 15}}>Депо</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{ConfirmMarkingPreset('Омега')}} disabled={load} style={{marginLeft: 15}}>Омега</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{ConfirmMarkingPreset('СПБ')}} disabled={load} style={{marginLeft: 15}}>СПБ</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{ConfirmMarkingPreset('WB')}} disabled={load} style={{marginLeft: 15}}>WB</button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{ConfirmMarkingPreset('Выставка')}} disabled={load} style={{marginLeft: 15}}>Выставка</button>
            
            </div>
            
            {reply1}
            </div>
        )
    }

    async function ReturnMarkingPreset(preset){
        console.log(codeToReturn)
        try {
            const response = await MarkingService.fetchReturnMarking({codeToReturn})
            console.log(response);

            setReply('Ликвидация успешно создана')
            setReturnOrder(false)
            setCodeToReturn('')
        } catch (e) {
            console.log(e);
            setReply(e.response?.data?.message + ' ' + e.response?.status)
            setReturnOrder(false)
            setCodeToReturn('')
        }
    }

    async function DelCounter(){
        console.log(codeToReturn);
        try {

            const response = await MarkingService.fetchDelConterMarking({codeToReturn})
            console.log(response);

            setReply('Возврат успешно создан')
            setReturnOrder(false)
            setCodeToReturn('')
        } catch (e) {
            console.log(e);
            setReply(e.response?.data?.message + ' ' + e.response?.status)
            setReturnOrder(false)
            setCodeToReturn('')
        }
    }

    const ReturnOrder = () => {
        return(
            <div>

                <div style={{marginTop: 25}}>
                <button type="button" className="btn btn-outline-danger" onClick={()=>{ReturnMarkingPreset()}} disabled={load}>Ликвидация ЧЗ</button>

                <button type="button" className="btn btn-outline-danger" onClick={()=>{DelCounter()}} disabled={load} style={{marginLeft: 10}}>Возврат на склад ЧЗ</button>
                </div>

            </div>
        )
    }
    

    return(
        <div>
            <div className='box' style={{marginTop: 50}}>
                <center><h1 className="fs-3">Проверка кодов маркировки</h1></center>
                <div className="input-group input-group-lg" style={{marginTop: 25}}>
                
                <input type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    onKeyUp={PressKey}
                    autoFocus={true}
                    disabled={load1}/>
                    <button type="button" className="btn btn-outline-secondary" onClick={Check} disabled={load1}>Проверить</button>
                </div>

                <center>
                    <div style={{marginTop: 25}}> {load ? <span className="loader"/> : <>{reply}</>}</div>
                    <div>{action ? otpravkaZakaza() : ""}</div>
                    <div>{returnOrder ? ReturnOrder() : ""}</div>
                </center>
            </div>
        </div>
    )
}